import SweetAlert from 'react-bootstrap-sweetalert'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import { useMemo, useState } from 'react'

const MfSweetAlert = props => {
  const [value, setValue] = useState('')

  const confirmBtnCssClass = useMemo(() => {
    if (!props.sweetAlert.confirmText) {
      return 'text-white'
    }
    return props.sweetAlert.confirmText.toLowerCase() !== value.toLowerCase() ? 'disabled' : 'text-white'
  }, [props.sweetAlert.confirmText, value])

  const customStyle = useMemo(() =>
    props.sweetAlert.width ? { width: props.sweetAlert.width, margin: '0 auto' } : undefined
  , [props.sweetAlert.width])

  return (
    <SweetAlert
      {...props.sweetAlert}
      confirmBtnCssClass={confirmBtnCssClass}
      style={customStyle}
      onConfirm={() => {
        if (props.sweetAlert.confirmInput) {
          setValue('')
          props.sweetAlert.onConfirm(value)
          return
        }
        props.sweetAlert.onConfirm()
      }}
    >
      {typeof props.sweetAlert.alertMessage === 'function'
        ? props.sweetAlert.alertMessage()
        : props.sweetAlert.alertMessage}
      {props.sweetAlert.confirmInput ? (
        <Input
          placeholder={props.sweetAlert.confirmInputPlaceholder}
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      ) : null}
    </SweetAlert>
  )
}

MfSweetAlert.propTypes = {
  sweetAlert: PropTypes.shape({
    alertMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    width: PropTypes.string,
  }),
}

MfSweetAlert.defaultProps = {
  sweetAlert: {
    alertMessage: '',
  },
}

const mapStateToProps = state => {
  return {
    sweetAlert: state.sweet_alert || {},
  }
}

export default connect(mapStateToProps, {})(MfSweetAlert)
