import React, { useEffect, useState } from 'react'
import logo from '../../../assets/images/logo/logo.png'

const SpinnerComponent = () => {
  const [topOffset, setTopOffset] = useState('40%')

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 600) {
        setTopOffset('30%')
      } else {
        setTopOffset('40%')
      }
    }
    window.addEventListener('resize', handleResize)
    // Set initial position
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className='fallback-spinner vh-100'>
      <img className='fallback-logo' src={logo} alt='logo' style={{ top: topOffset }} />
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent;