import _ from 'lodash';
import {  CREATE_TEMPLATE, FETCH_TEMPLATES, FETCH_TEMPLATE, EDIT_TEMPLATE } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_TEMPLATES:
        return _.mapKeys(action.payload, 'id');
    case CREATE_TEMPLATE:
      return { ...state, [action.payload.id]: action.payload };
    case FETCH_TEMPLATE:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_TEMPLATE:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
};