import _ from 'lodash';
import { FETCH_PAGINATED, FETCH_PAGINATED_CLEAR  } from '../actions/types'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_PAGINATED:
      return {
        ...state,
        [action.payload.model]: {
          ...state[action.payload.model], // keeps page
          [action.payload.page]: JSON.parse(action.payload.results),
          pages: action.payload.pages,
          total_rows: action.payload.total_rows
        }
      }
    case FETCH_PAGINATED_CLEAR:
      return {
        ...state,
        [action.payload.model]: {
          [action.payload.page]: JSON.parse(action.payload.results),
          pages: action.payload.pages,
          total_rows: action.payload.total_rows
        }
      }
    default:
      return state;
  }
};
