import { FETCH_REFUND_SUMMARY } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_REFUND_SUMMARY:
      return action.payload
    default:
      return state
  }
}
