import _ from 'lodash';
import {
  FETCH_PRODUCT_CONFIGURABLE_VARIANTS,
  FETCH_PRODUCT_CONFIGURABLE_VARIANT,
  CREATE_PRODUCT_CONFIGURABLE_VARIANT,
  EDIT_PRODUCT_CONFIGURABLE_VARIANT,
  DELETE_PRODUCT_CONFIGURABLE_VARIANT,
  BULK_UPDATE_CONFIGURABLE_VARIANTS,
  FETCH_PRODUCT_ASSIGNED_VARIANTS
} from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PRODUCT_CONFIGURABLE_VARIANTS:
      return action.payload;
      // return { ...state, ..._.mapKeys(action.payload, 'id') };
    case FETCH_PRODUCT_CONFIGURABLE_VARIANT:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_PRODUCT_CONFIGURABLE_VARIANT:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_PRODUCT_CONFIGURABLE_VARIANT:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_PRODUCT_CONFIGURABLE_VARIANT:
      return _.omit(state, action.payload );
    case BULK_UPDATE_CONFIGURABLE_VARIANTS:
      return action.payload;
    case FETCH_PRODUCT_ASSIGNED_VARIANTS:
      return { ...state, assigned_variants: action.payload };
    default:
      return state;
  }
};
