import _ from 'lodash';
import {FETCH_ORDERS, FETCH_ORDER, CANCEL_ORDER} from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_ORDERS:
      return { ...state, ..._.mapKeys(action.payload, 'id') };
    case CANCEL_ORDER:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
};
