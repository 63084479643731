
import { useMFAdminAppInitializer } from "../../contexts/MFAdminAppInitializer"
import AgencyBanner from "./AgencyBanner"
import { Button } from 'devextreme-react/button'

const EnvironmentBanner = () => {
  const { state: { env } } = useMFAdminAppInitializer()

  const clearLocalStorage = () => {
    localStorage.clear()
    window.location.reload()
  }

  if (env && env !== 'production') {
    return (
      <>
        <div id="current-environment">
          <p>{`${env.toUpperCase()} SITE`}</p>
          <AgencyBanner />
        </div>
        {/* <div>
        {environment === 'development' && <Button type="danger" height="22" onClick={clearLocalStorage}>Clear LS & Refresh</Button>}
        </div> */}
      </>
    )
  } else {
    return (
      <AgencyBanner />
    )
  }
}

export default EnvironmentBanner
