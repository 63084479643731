import _ from 'lodash';
import { FETCH_FUNDING_TAGS } from '../actions/types'
import { CLEAR_FUNDING_TAGS } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_FUNDING_TAGS:
      return action.payload
    case CLEAR_FUNDING_TAGS:
        return [];
    default:
      return state;
  }
};
