import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import stocks from './stocksReducer'
import clientAdminsReducer from './clientAdminsReducer'
import clientAdminsByUserReducer from './clientAdminsByUserReducer'
import clients from './clientsReducer'
import clientsCcDataPoints from './clientsCcDataPoints'
import audits from './auditsReducer'
import portals from './portalsReducer'
import pages from './pagesReducer'
import templates from './templatesReducer'
import { reducer as toastr } from 'react-redux-toastr'
import domains from './domainsReducer'
import clientImagesReducer from './clientImagesReducer'
import sendBalanceRemindersReducer from './sendBalanceRemindersReducer'
import getLastReminderSent from './getLastReminderSent'
import products from './productsReducer'
import session from './sessionsReducer'
import errors from './errorsReducer'
import status from './statusReducer'
import admins from './adminsReducer'
import agencies from './agenciesReducer'
import users from './usersReducer'
import locations from './locationsReducer'
import vendors from './vendorsReducer'
import aggregatedClientProductReducer from './aggregatedClientProductReducer'
import aggregatedPortalProductReducer from './aggregatedPortalProductReducer'
import aggregatedLocationProductReducer from './aggregatedLocationProductReducer'
import aggregatedGenericProductReducer from './aggregatedGenericProductReducer'
import productConfigurableVariantsReducer from './productConfigurableVariantsReducer'
import categories from './categoriesReducer'
import subCategoriesReducer from './subCategoriesReducer'
import locationCategoryProductsReducer from './locationCategoryProductsReducer'
import configWizardForm from './configWizardFormReducer'
import configurableOptions from './configurableOptionsReducer'
import user_portals from './userPortalsReducer'
import user_locations from './userLocationsReducer'
import customerGroupReducer from './customerGroupReducer'
import userPortalsCustomerGroupsReducer from './userPortalsCustomerGroupsReducer'
import customerGroupRulesReducer from './customerGroupRulesReducer'
import customerGroupsForUserReducer from './customerGroupsForUserReducer'
import warehouses from './warehouseReducer'
import inventoryLog from './inventoryLogReducer'
import sortedSkusReducer from './sortedSkusReducer'
import menusReducer from './menusReducer'
import cartsReducer from './cartsReducer'
import credits from './creditsReducer'
import creditBalancesReducer from './creditBalancesReducer'
import externalAccountsReducer from './externalAccountsReducer'
import productPortalsReducer from './productPortalsReducer'
import productLocationsReducer from './productLocationsReducer'
import productClentsReducer from './productClentsReducer'
import pendingOrdersReducer from './pendingOrdersReducer'
import orders from './ordersReducer'
import order from './orderReducer'
import cardPaymentsReducer from './cardPaymentsReducer'
import splitOrderReducer from './orderSplitReducer'
import orderSplittedSubOrdersReducer from './orderSplittedSubOrdersReducer'
import systemSettingsReducer from './systemSettingsReducer'
import images from './imagesReducer'
import productSkusReducer from './productSkusReducer'
import bundles from './bundleReducer'
import bundleGroupReducer from './bundleGroupReducer'
import periods from './periodsReducer'
import artworkPreviewReducer from './artworkPreviewReducer'
import artworks from './artworkReducer'
import subOrderReducer from './subOrderReducer'
import portalAccountsReducer from './portalAccountsReducer'
import tags from './tagsReducer'
import uploadResultsReducer from './uploadResultsReducer'
import uploadHistoryReducer from './uploadHistoryReducer'
import clientPortalsReducer from './clientPortalsReducer'
import artworkFamilyReducer from './artworkFamilyReducer'
import portalTraditionalDeliveryAddressReducer from './portalTraditionalDeliveryAddressReducer'
import promotions from './promotionsReducer'
import promotionRulesReducer from './promotionRulesReducer'
import promotionCodesReducer from './promotionCodesReducer'
import promotionValidationsReducer from './promotionValidationsReducer'
import smartAddressReducer from './smartAddressReducer'
import refundOrderReducer from './refundOrdersReducer'
import refunds from './refundsReducer'
import carriers from './carriersReducer'
import carrierIds from './carrierIdsReducer'
import shippingLabelReducer from './shippingLabelReducer'
import paginatedReducer from './paginatedReducer'
import purchaseOrderReducer from './purchaseOrderReducer'
import purchaseOrderItemReducer from './purchaseOrderItemReducer'
import shipperHqReducer from './shipperHqReducer'
import shipperHqOriginsReducer from './shipperHqOriginsReducer'
import shipperHqPackingRulesReducer from './shipperHqPackingRulesReducer'
import shipperHqBoxesReducer from './shipperHqBoxesReducer'
import productShippingGroupReducer from './productShippingGroupReducer'
import productShippingOriginReducer from './productShippingOriginReducer'
import productShippingBoxReducer from './productShippingBoxReducer'
import productShqPackingReducer from './productShqPackingReducer'
import refundsSummaryReducer from './refundsSummaryReducer'
import portalUsersReducer from './portalUsersReducer'
import orderAfterRefundsReducer from './orderAfterRefundsReducer'
import orderRefundStatusesReducer from './orderRefundStatusesReducer'
import goodsReceiptReducer from './goodsReceiptReducer'
import shipmentReducer from './shipmentReducer'
import settings from './settingsReducer'
import templateFormsReducer from './templateFormsReducer'
import tierPriceReducer from './tierPriceReducer'
import digitalProofingItems from './digitalProofingReducer'
import roles from './roleReducer'
import permissions from './permissionReducer'
import portalTagSetsReducer from './portalTagSetsReducer'
import showPortalTagSetsReducer from './showPortalTagSetsReducer'
import portalTagsReducer from './portalTagsReducer'
import whitelistIpsReducer from './whitelistIpsReducer'
import blacklistIpsReducer from './blacklistIpsReducer'
import AuthorizedAdminEmailDomainsReducer from './AuthorizedAdminEmailDomainsReducer'
import dropshipBatchesReducer from './dropshipBatchesReducer'
import dropshipTemplatesReducer from './dropshipTemplatesReducer'
import portalPortalTagsReducer from './portalPortalTagsReducer'
import creditsCoopBalancesByTagReducer from './creditsCoopBalancesByTagReducer'
import carrierRates from './carrierRatesReducer'
import carrierRatesMultiBox from './carrierRatesMultiBoxReducer'
import thirdPartyBillings from './thirdPartyBillingsReducer'
import shipFromAddresses from './shipFromAddressesReducer'
import alertReducer from './alertReducer'
import shippingDimensionsReducer from './shippingDimensionsReducer'
import carrierMethods from './carrierMethodsReducer'
import dashboardReducer from './dashboardReducer'
import locationGroupsReducer from "./locationGroupsReducer"
import dropshipImagesReducer from './dropshipImagesReducer'
import ccDataPointsReducer from './ccDataPointsReducer'
import checkoutFormReducer from './checkoutFormReducer'
import loading from './loadingReducer'
import goodsReceiptsLogsReducer from './goodsReceiptsLogsReducer'
import mfReducer from './mfReducer'

import buttonsReducer from "./buttonsReducer.js";
import userDetailsReducer from "./userDetailsReducer.js"
import orderErrorsReducer from "./orderErrorsReducer";
// vuexy
import navbar from './navbar'
import layout from './layout'

export default combineReducers({
  admins,
  agencies,
  aggregated_client_product: aggregatedClientProductReducer,
  aggregated_portal_product: aggregatedPortalProductReducer,
  aggregated_location_product: aggregatedLocationProductReducer,
  aggregated_generic_product: aggregatedGenericProductReducer,
  all_paginated_models: paginatedReducer,
  audits,
  authorized_admin_email_domains: AuthorizedAdminEmailDomainsReducer,
  artworks,
  artwork_families: artworkFamilyReducer,
  artwork_preview: artworkPreviewReducer,
  blacklisted_ips: blacklistIpsReducer,
  bundle_groups: bundleGroupReducer,
  bundles,
  buttons:                    buttonsReducer,
  card_payments: cardPaymentsReducer,
  carriers: carriers,
  carrierIds: carrierIds,
  carrierMethods: carrierMethods,
  carrierRates: carrierRates,
  carrierRatesMultiBox: carrierRatesMultiBox,
  carts: cartsReducer,
  categories: categories,
  ccdps: clientsCcDataPoints,
  cc_data_points: ccDataPointsReducer,
  checkout_forms: checkoutFormReducer,
  client_admins: clientAdminsReducer,
  client_admins_by_user: clientAdminsByUserReducer,
  client_images: clientImagesReducer,
  client_portals: clientPortalsReducer,
  clients,
  configurable_options: configurableOptions,
  configWizardForm,
  coop_balances_by_tag: creditsCoopBalancesByTagReducer,
  credit_balances: creditBalancesReducer,
  credits,
  customer_group_rules: customerGroupRulesReducer,
  customer_groups: customerGroupReducer,
  customer_groups_for_user: customerGroupsForUserReducer,
  dashboard: dashboardReducer,
  digitalProofingItems,
  domains,
  dropship_batches: dropshipBatchesReducer,
  dropship_images: dropshipImagesReducer,
  dropship_templates: dropshipTemplatesReducer,
  errors,
  external_accounts: externalAccountsReducer,
  form: formReducer,
  funding_tags: portalPortalTagsReducer,
  get_last_reminder_sent: getLastReminderSent,
  goods_receipt: goodsReceiptReducer,
  goods_receipts_logs: goodsReceiptsLogsReducer,
  images: images,
  loading: loading,
  locations: locations,
  locationGroups:             locationGroupsReducer,
  location_category_products: locationCategoryProductsReducer,
  mf: mfReducer,
  menu_items: menusReducer,
  orders,
  order,
  order_after_refunds: orderAfterRefundsReducer,
  order_errors_count: orderErrorsReducer,
  order_refund_statuses: orderRefundStatusesReducer,
  pages,
  pending_orders: pendingOrdersReducer,
  periods: periods,
  permissions: permissions,
  portal_tags: portalTagsReducer,
  portal_users: portalUsersReducer,
  portals,
  portal_traditional_delivery_addresses: portalTraditionalDeliveryAddressReducer,
  portal_accounts: portalAccountsReducer,
  product_clients: productClentsReducer,
  product_configurable_variants: productConfigurableVariantsReducer,
  product_locations: productLocationsReducer,
  product_packing_rule: productShqPackingReducer,
  product_portals: productPortalsReducer,
  product_shipping_groups: productShippingGroupReducer,
  product_shipping_origins: productShippingOriginReducer,
  product_shipping_boxes: productShippingBoxReducer,
  product_skus: productSkusReducer,
  product_skus_sorted: sortedSkusReducer,
  products,
  promotion_rules: promotionRulesReducer,
  promotion_codes: promotionCodesReducer,
  promotion_validations: promotionValidationsReducer,
  promotions,
  purchase_order_items: purchaseOrderItemReducer,
  purchase_orders: purchaseOrderReducer,
  // purchase_orders:            purchaseOrderReducer,
  refund_orders: refundOrderReducer,
  refunds,
  refunds_summary: refundsSummaryReducer,
  roles,
  send_balance_reminders: sendBalanceRemindersReducer,
  session,
  settings,
  shipping_labels: shippingLabelReducer,
  shipping_hq: shipperHqReducer,
  shipping_boxes: shipperHqBoxesReducer,
  shipping_packing_rules: shipperHqPackingRulesReducer,
  shipping_origins: shipperHqOriginsReducer,
  shipping_dimensions: shippingDimensionsReducer,
  shipment_details: shipmentReducer,
  show_tag_sets: showPortalTagSetsReducer,
  smart_addresses: smartAddressReducer,
  split_order: splitOrderReducer,
  splitted_sub_orders: orderSplittedSubOrdersReducer,
  status,
  stocks,
  sub_categories: subCategoriesReducer,
  sub_orders: subOrderReducer,
  system_settings: systemSettingsReducer,
  sweet_alert: alertReducer,
  tags,
  template_form: templateFormsReducer,
  tier_price: tierPriceReducer,
  tag_sets: portalTagSetsReducer,
  templates,
  thirdPartyBillings,
  toastr,
  uploaded_results: uploadResultsReducer,
  upload_history: uploadHistoryReducer,
  user_portals,
  user_locations,
  user_portals_customer_groups: userPortalsCustomerGroupsReducer,
  user_details:               userDetailsReducer,
  users,
  vendors,
  warehouses,
  inventoryLog,
  shipFromAddresses,
  whitelisted_ips: whitelistIpsReducer,

  // vuexy
  navbar,
  layout,
})
