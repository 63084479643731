import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation'
import { Button, Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import microsoftLogo from '../../assets/auth0_buttons/microsoftlogo.png'
import googleLogo from '../../assets/auth0_buttons/googlelogo.png'
import { useAuth0 } from '@auth0/auth0-react'
import { REACT_APP_AUTH0_REDIRECT_URI } from '../../Constants'
import LoadingSpinner from '../general/LoadingSpinner'
import { clearLoginStatus, createSession } from '../../actions/sessions'
import MFLogo from '../logo'

const SessionNew = ({ session, errors, status, createSession, clearLoginStatus }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [token, setToken] = useState('') // 2FA token
  const [spinnerVisibility, setSpinnerVisibility] = useState(false)
  const [formVisibility, setFormVisibility] = useState(true)
  const [successVisibility, setSuccessVisibility] = useState(false)
  const [tokenRequired, setTokenRequired] = useState(false)
  const [tokenResent, setTokenResent] = useState(false)

  useEffect(() => {
    const currentStatus = status.message
    switch (currentStatus) {
      case 'SUCCESS':
        setSpinnerVisibility(false)
        setFormVisibility(false)
        setSuccessVisibility(true)
        break
      case 'FAILED':
        setSpinnerVisibility(false)
        setFormVisibility(true)
        setSuccessVisibility(false)
        setTokenRequired(errors.length !== 0 && errors[1] === 'token_required')
        break
      case 'LOADING':
        setSpinnerVisibility(true)
        setFormVisibility(false)
        setSuccessVisibility(false)
        break
      default:
        break
    }
  }, [status, errors])

  // on unmount, reset status
  useEffect(() => {
    clearLoginStatus()
    // return () => {
    //   clearLoginStatus()
    // }
  }, [])

  const handleValidSubmit = e => {
    e.preventDefault()
    const formData = { email, password, token }
    createSession(formData)
  }

  const handleChange = e => {
    const { name, value } = e.target
    if (name === 'email') setEmail(value)
    else if (name === 'password') setPassword(value)
    else if (name === 'token') setToken(value)
  }

  const resendToken = () => {
    if (tokenRequired && !tokenResent) {
      const formData = new FormData()
      formData.append('email', email)
      formData.append('password', password)
      formData.append('token', '')
      createSession(formData)
      setTokenResent(true)
    }
  }

  const { loginWithRedirect } = useAuth0()
  const handleGoogleSignIn = async () => {
    await loginWithRedirect({
      authorizationParams: {
        connection: 'google-oauth2',
        redirectUri: REACT_APP_AUTH0_REDIRECT_URI,
        prompt: 'select_account',
      },
    })
  }

  const handleMicrosoftSignIn = async () => {
    await loginWithRedirect({
      authorizationParams: {
        connection: 'windowslive',
        redirectUri: REACT_APP_AUTH0_REDIRECT_URI,
        prompt: 'select_account',
      },
    })
  }

  const DividerWithText = ({ children }) => (
    <div className="login-divider-container">
      <div className="login-divider-border" />
      <span className="login-divider-text">{children}</span>
      <div className="login-divider-border" />
    </div>
  )

  return (
    <div className="app flex-row align-items-center login-container">
      <Container>
        <Row className="login-page justify-content-center align-items-center">
          <Col md="8">
            <CardGroup>
              <Card className="text-white py-5 d-md-down-none login-card" style={{ width: '44%' }}>
                <CardBody className="d-flex justify-content-center align-items-center login-card">
                  <div className="admin_login_logo">
                    <MFLogo variant="icon" />
                  </div>
                </CardBody>
              </Card>
              <Card className="p-2 login-card">
                <CardBody>
                  <h1>Admin Sign In</h1>
                  <p className="text-muted">{tokenRequired ? 'Required token:' : 'Sign In to your account'}</p>
                  {formVisibility && (
                    <AvForm onValidSubmit={handleValidSubmit}>
                      <Row>
                        <Col md="12">
                          {errors.length !== 0 && errors[0] !== '' && (
                            <div className="alert alert-danger" role="alert">
                              <p>{errors[0]}</p>
                            </div>
                          )}
                        </Col>
                      </Row>
                      {!tokenRequired && (
                        <>
                          <Row>
                            <Col>
                              <AvGroup className="mb-2">
                                <AvField
                                  onChange={handleChange}
                                  type="text"
                                  placeholder="Email"
                                  autoComplete="email"
                                  name="email"
                                  className="sign-in-input"
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Please enter an email',
                                    },
                                    email: {
                                      value: true,
                                      errorMessage: 'Please enter a valid email',
                                    },
                                  }}
                                />
                              </AvGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <AvGroup className="mb-2">
                                <AvField
                                  onChange={handleChange}
                                  type="password"
                                  placeholder="Password"
                                  autoComplete="current-password"
                                  name="password"
                                  className="sign-in-input"
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Please enter a password',
                                    },
                                  }}
                                />
                              </AvGroup>
                            </Col>
                          </Row>
                        </>
                      )}
                      {tokenRequired && (
                        <AvGroup className="mb-2">
                          <AvField
                            onChange={handleChange}
                            type="text"
                            placeholder="token"
                            autoComplete="token"
                            name="token"
                            className="sign-in-input"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'Please enter an token',
                              },
                            }}
                          />
                        </AvGroup>
                      )}
                      <Row>
                        <Col>
                          <Button type="submit" className="px-4 full-width-button login-button">
                            Sign In
                          </Button>
                        </Col>
                      </Row>
                      {tokenRequired && !tokenResent && (
                        <Row className="mt-1">
                          <Col>
                            <Button onClick={resendToken} className="px-4 full-width-button token-button">
                              Re-Send Token
                            </Button>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <DividerWithText>or</DividerWithText>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            className="px-4 full-width-button d-flex justify-content-center align-items-center sso-button google-signin-button"
                            onClick={handleGoogleSignIn}
                          >
                            <img src={googleLogo} alt="Sign In with Google" />
                            Sign In with Google
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            className="px-4 full-width-button d-flex justify-content-center align-items-center sso-button microsoft-signin-button"
                            onClick={handleMicrosoftSignIn}
                          >
                            <img src={microsoftLogo} alt="Sign In with Microsoft" />
                            Sign In with Microsoft
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pt-2 mb-15 mt-15">
                          <p>
                            <Link to="/forgot_password">Forgot Your Password?</Link>
                          </p>
                        </Col>
                      </Row>
                    </AvForm>
                  )}
                  {spinnerVisibility && <LoadingSpinner />}
                  {successVisibility && <h1>SUCCESS!</h1>}
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const mapStateToProps = state => ({
  session: Object.values(state.session),
  errors: Object.values(state.errors),
  status: state.status,
})

export default connect(mapStateToProps, { createSession, clearLoginStatus })(SessionNew)
export { SessionNew }
