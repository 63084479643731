import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createAuthorizedAdminEmailDomain } from '../../actions';
import history from "../../history";
const AuthorizedAdminEmailDomainForm = React.lazy(() => import('./AuthorizedAdminEmailDomainForm'));


import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';

class AuthorizedAdminEmailDomainCreate extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    // this.props.onLoad('/new_admin_email_domain', 'New Authorized Admin Domain')
  }

  onSubmit = (formValues) => {
    this.props.createAuthorizedAdminEmailDomain(formValues);
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card className="card-accent-primary">
              <CardHeader>
                <strong>New</strong> Domain
              </CardHeader>
              <CardBody>
                <AuthorizedAdminEmailDomainForm
                  buttonText = "Create Domain"
                  onSubmit={this.onSubmit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect(null, { createAuthorizedAdminEmailDomain })(AuthorizedAdminEmailDomainCreate);

// export for testing
export { AuthorizedAdminEmailDomainCreate };