export const NODE_ENV = process.env.NODE_ENV
export const REACT_APP_ENV = process.env.REACT_APP_ENV
export const REACT_APP_BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL
export const REACT_APP_PORTAL_TOKEN = process.env.REACT_APP_PORTAL_TOKEN
export const ASSET_PATH = process.env.ASSET_PATH

export const ADMIN_APP_VERSION = require('../package.json').version

let Auth0Credentials = {}

Auth0Credentials = JSON.parse(process.env.AUTH0_CREDENTIALS)

export const REACT_APP_AUTH0_DOMAIN       = Auth0Credentials.AUTH0_DOMAIN
export const REACT_APP_AUTH0_CLIENT_ID    = Auth0Credentials.AUTH0_CLIENT_ID
export const REACT_APP_AUTH0_REDIRECT_URI = Auth0Credentials.AUTH0_REDIRECT_URI


export const dpTypes = ['Business Card', 'Door Hanger', 'Event Flyer', 'Flyer', 'Postcard', 'Printed Coupon', 'Stickers']
export const bcTypes = [
  'Name and Position',
  'Name and Position - Color',
  'Name and Position - CSAT',
  'Fill in the Blank',
  'Fill in the Blank - Color',
  'Fill in the Blank - CSAT',
  'TW Wireless - Name',
  'TW Wireless - Blank',
  'WS With Address',
  'WS Without Address',
  'PE Appointment Card',
  'PE Address with Title',
  'PE Address without Title',
]
export const flyerTypes = [
  'Default',
  'T-MO Welcome Kit',
  'PE Now Open',
  'PE Opening Soon',
  'PE Event Handout',
  'PE Evergreen',
  'PE Leave Behind - Opening Soon',
  'PE Direct Mail - Opening Soon',
]
export const pdfTypes = ['Add-on', 'Artwork', 'Coupon']
export const poStatuses = ['Unsent', 'Sent', 'Partial', 'Received', 'Cancelled']
export const configVariantDisplayTypes = ['Dropdown', 'Swatch', 'Button']

export const COLORS = {
  primary: '#2b5995',
  fontPrimary: '#0e0e0e',
}

// define routes that skip session check
export const SESSION_ROUTES = ['login', 'auth0', 'sso-log-in', 'reset_password']
