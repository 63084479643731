// ** React Imports
import React, { Fragment, useState, useRef, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { fetchAdminPermissions } from '../../../../../actions'
import HandlePackageJsonVersion from '@components/general/HandlePackageJsonVersion'

// ** Vertical Menu Items Array
import navWithPermissions from '../../../../../_nav'

// ** Third Party Components
import classnames from 'classnames'

// ** Vertical Menu Components
import VerticalMenuHeader from './VerticalMenuHeader'
import VerticalNavMenuItems from './VerticalNavMenuItems'
import { useMFAdminAppInitializer } from '../../../../../contexts/MFAdminAppInitializer'

const MFSidebar = props => {
  const { routerProps, menu, currentActiveItem, menuVisibility } = props

  const { state: { layout: { skin } } } = useMFAdminAppInitializer()

  const { menuCollapsed } = useSelector(state => state.layout)

  const [groupOpen, setGroupOpen] = useState([])
  const [groupActive, setGroupActive] = useState([])
  const [activeItem, setActiveItem] = useState(null)
  const [userNavigation, setUserNavigation] = useState([])

  // ** Menu Hover State
  const [menuHover, setMenuHover] = useState(false)

  // ** Ref
  const shadowRef = useRef(null)

  // ** Function to handle Mouse Enter
  const onMouseEnter = () => {
    if (menuCollapsed) {
      setMenuHover(true)
    }
  }

  // when permissions are set, build the sidebar navigation accordingly
  useEffect(() => {
    // server returns either an array or false. otherwise will be undefined
    if (props.permissions || props.permissions === false) {
      const navObj = navWithPermissions(props.permissions, props.current_admin, skin)
      setUserNavigation(navObj)
    }
  }, [props.permissions, props.current_admin, skin])

  return (
    <Fragment>
      <div
        className={classnames('main-menu menu-fixed menu-accordion menu-shadow', {
          expanded: menuHover || menuCollapsed === false || menuVisibility
          // 'menu-light': skin !== 'semi-dark' && skin !== 'dark',
          // 'menu-dark': skin === 'semi-dark' || skin === 'dark',
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setMenuHover(false)}
      >
        {menu ? (
          menu
        ) : (
          <Fragment>
            {/* Vertical Menu Header */}
            <VerticalMenuHeader setGroupOpen={setGroupOpen} menuHover={menuHover} {...props} />
            {/* Vertical Menu Header Shadow */}
            <div className="shadow-bottom" ref={shadowRef}></div>
            <ul
              className="navigation navigation-main"
              // style={{
              //   display: 'flex',
              //   flexDirection: 'column',
              //   minHeight: 'calc(100% - 4.45rem)',
              // }}
            >
              {/* route / links = items */}
              <VerticalNavMenuItems
                items={userNavigation.items}
                groupActive={groupActive}
                setGroupActive={setGroupActive}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                groupOpen={groupOpen}
                setGroupOpen={setGroupOpen}
                routerProps={routerProps}
                menuHover={menuHover}
                currentActiveItem={currentActiveItem}
              />
              {/*Output Admin Version*/}
              <li
                className="nav-item app-version"
                style={{
                  // padding: '10px 30px',
                  // color: '#fff',
                  // fontSize: '10px',
                }}
              >
                a/ {HandlePackageJsonVersion()}
              </li>
            </ul>
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    user: Object.values(state.admins),
    current_admin: state.admins.current_admin,
    permissions: state.permissions
  }
}

export default connect(mapStateToProps, {
  fetchAdminPermissions
})(MFSidebar)
