import PropTypes from 'prop-types'
import { forwardRef, useMemo } from 'react'

/**
 * @typedef {'full' | 'icon' | 'text'} LogoVariant
 * @typedef {'brand' | 'black' | 'white'} ColorVariant
 *
 * @typedef {Object} MFLogoProps
 * @property {LogoVariant | undefined} variant - Identifies the render of either the icon, text or both.
 * @property {ColorVariant | undefined} iconFill - Sets the color of the icon.
 * @property {Omit<ColorVariant, 'brand'> | undefined} textFill - Sets the color of the text.
 * @property {number | undefined} size - Sets the size of the logo according to a number value that indicates the width.
 */

/**
 * @type {MFLogoProps}
 */
const propTypes = {
  /**
   * @default 'full'
   */
  variant: PropTypes.oneOf(['full', 'icon', 'text']),

  /**
   * @default 'brand'
   */
  iconFill: PropTypes.oneOf(['brand', 'black', 'white']),

  /**
   * @default 'black'
   */
  textFill: PropTypes.oneOf(['black', 'white']),

  /**
   * If undefined, logo will be set to 100% width of the parent element.
   * @default undefined
   */
  size: PropTypes.number,
}

/**
 * @type {MFLogoProps}
 */
const defaultProps = {
  variant: 'full',
  iconFill: 'brand',
  textFill: 'black',
}

const brandColorMap = {
  black: '#202025',
  white: '#ffffff',
}

/**
 *
 * @param {Pick<MFLogoProps, 'iconFill' | 'textFill'>} props
 * @returns {JSX.Element} Renders logo with icon and text side by side.
 */
const FullLogo = ({ iconFill = 'brand', textFill = 'black' }) => (
  <>
    <path
      d="M355.008 155.29V108.104C355.008 96.7472 349.98 90.9223 340.97 90.9223C331.961 90.9223 326.472 96.7472 326.472 108.104V155.29H302.504V108.104C302.504 96.7472 297.475 90.9223 288.466 90.9223C279.457 90.9223 273.968 96.7472 273.968 108.104V155.29H250V94.6938C250 81.4515 260.727 70.7235 273.968 70.7235V82.7087C279.499 75.6265 286.748 69.1311 298.9 69.1311C309.962 69.1311 318.301 74.0341 322.701 82.541C330.118 73.8665 338.959 69.1311 350.482 69.1311C368.291 69.1311 379.018 79.8591 379.018 100.225V155.29H355.05H355.008Z"
      fill={brandColorMap[textFill]}
    />
    <path
      d="M446.527 154.759V145.623C440.702 152.077 432.657 156.351 420.966 156.351C405.043 156.351 391.928 147.216 391.928 130.495V130.16C391.928 111.679 405.965 103.172 425.995 103.172C434.501 103.172 440.661 104.597 446.653 106.651V105.226C446.653 95.2941 440.493 89.7625 428.509 89.7625C419.374 89.7625 412.879 91.4806 405.169 94.3303L399.177 76.0173C408.479 71.9105 417.614 69.2285 431.987 69.2285C458.176 69.2285 469.699 82.8061 469.699 105.687V154.759H446.527ZM447.03 120.689C442.923 118.803 437.56 117.546 431.735 117.546C421.469 117.546 415.184 121.653 415.184 129.238V129.573C415.184 136.027 420.547 139.84 428.257 139.84C439.445 139.84 447.03 133.68 447.03 125.006V120.731V120.689Z"
      fill={brandColorMap[textFill]}
    />
    <path
      d="M533.765 94.2316C517.842 94.2316 508.037 103.87 508.037 124.069V155.289H484.068V70.7223H508.037V87.7781C512.939 76.0863 520.817 68.5432 535.022 69.1718V94.2735H533.765V94.2316Z"
      fill={brandColorMap[textFill]}
    />
    <path
      d="M598.572 155.518L576.489 120.946L568.109 129.788V155.518H544.141V40.3604H568.109V101.753L596.184 70.9937H624.887L592.706 104.267L625.977 155.56H598.53L598.572 155.518Z"
      fill={brandColorMap[textFill]}
    />
    <path
      d="M708.834 121.388H650.003C652.349 132.284 659.934 137.941 670.661 137.941C678.706 137.941 684.531 135.427 691.151 129.266L704.895 141.419C697.018 151.183 685.662 157.176 670.326 157.176C644.933 157.176 626.16 139.366 626.16 113.468V113.132C626.16 88.9946 643.34 69.1311 667.979 69.1311C696.221 69.1311 709.169 91.048 709.169 115.06V115.395C709.169 117.742 709.002 119.167 708.834 121.388ZM667.979 88.4079C658.048 88.4079 651.553 95.49 649.668 106.386H685.788C684.363 95.6577 678.078 88.4079 667.979 88.4079Z"
      fill={brandColorMap[textFill]}
    />
    <path
      d="M750.949 156.715C736.283 156.715 726.352 150.89 726.352 131.32V91.2577H716.254V70.7656H726.352V49.1421H750.321V70.7656H770.183V91.2577H750.321V127.381C750.321 132.912 752.667 135.594 758.073 135.594C762.473 135.594 766.411 134.505 769.889 132.577V151.812C764.861 154.829 758.995 156.715 750.949 156.715Z"
      fill={brandColorMap[textFill]}
    />
    <path
      d="M822.762 59.2406C817.231 59.2406 814.089 62.0903 814.089 68.3762V71.6867H833.49V91.2569H814.382V155.331H790.414V91.2569H780.483V71.5191H790.414V66.1551C790.414 47.5488 799.716 39 816.603 39C824.187 39 829.215 39.9638 833.657 41.3467V61.2102C830.179 59.953 826.869 59.1568 822.762 59.1568V59.2406Z"
      fill={brandColorMap[textFill]}
    />
    <path
      d="M897.856 155.288V143.303C892.325 150.385 885.244 156.88 873.092 156.88C854.948 156.88 844.389 144.895 844.389 125.493V70.7632H868.357V117.949C868.357 129.306 873.721 135.131 882.855 135.131C891.99 135.131 897.856 129.306 897.856 117.949V70.7632H921.825V155.33H897.856V155.288Z"
      fill={brandColorMap[textFill]}
    />
    <path
      d="M1017.28 121.388H958.448C960.795 132.284 968.379 137.941 979.106 137.941C987.152 137.941 992.976 135.427 999.597 129.266L1013.34 141.419C1005.46 151.183 994.108 157.176 978.771 157.176C953.378 157.176 934.606 139.366 934.606 113.468V113.132C934.606 88.9946 951.786 69.1311 976.425 69.1311C1004.67 69.1311 1017.61 91.048 1017.61 115.06V115.395C1017.61 117.742 1017.45 119.167 1017.28 121.388ZM976.425 88.4079C966.494 88.4079 959.999 95.49 958.113 106.386H994.233C992.809 95.6577 986.481 88.4079 976.425 88.4079Z"
      fill={brandColorMap[textFill]}
    />
    <path
      d="M1031.94 155.289V64.1011C1031.94 50.8588 1042.67 40.1309 1055.91 40.1309V155.289H1031.94Z"
      fill={brandColorMap[textFill]}
    />
    <rect
      width="200"
      height="200"
      rx="40"
      fill={iconFill === 'brand' ? 'url(#paint0_linear_213_904)' : brandColorMap[iconFill]}
    />
    <path
      d="M123.079 65.423C124.295 45.5595 131.711 39.4412 141.894 30.2638C114.867 28.1685 89.683 38.645 71.2458 58.5504C59.9321 70.7031 52.1382 86.0408 48.7022 102.3C46.0623 114.956 46.188 127.612 51.2583 139.639C56.6637 152.504 68.6898 168.386 83.5652 170.356C80.8415 167.506 78.2017 164.07 77.2798 162.771C75.1428 159.795 73.341 156.569 71.8744 153.216C63.7872 134.652 66.5946 109.843 86.3727 100.037C85.0737 106.7 88.4678 113.489 96.9322 115.626C97.0998 102.007 105.397 90.1056 118.847 86.9627C117.213 87.3398 115.872 93.081 115.579 94.4639C115.034 97.104 115.202 99.7859 115.579 102.426C116.459 109.047 119.476 113.07 122.786 118.811C127.437 126.899 128.946 136.789 126.474 145.841C124.001 154.892 116.794 163.483 110.09 170.356C123.834 169.685 135.189 159.628 141.349 147.852C145.539 139.932 146.964 130.587 146.838 121.703C146.671 109.927 139.547 102.133 136.153 91.4466C132.298 79.252 138.416 68.0211 146.88 60.1009C146.88 60.1009 142.606 59.3466 142.019 59.3047C139.882 59.0951 137.745 59.1371 135.65 59.4304C132.089 59.9333 125.133 61.6514 123.079 65.5068V65.423Z"
      fill={iconFill === 'white' ? brandColorMap.black : brandColorMap.white}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M199.996 63.2683C200 65.4187 200 67.6606 200 70V130C200 158.089 200 172.134 193.259 182.223C190.34 186.59 186.59 190.34 182.223 193.259C172.134 200 158.089 200 130 200H70C41.9107 200 27.8661 200 17.7771 193.259C13.4095 190.34 9.65951 186.59 6.74117 182.223C2.71564 176.198 1.09396 168.763 0.440674 157.769C29.5586 99.9672 92.0763 60 164.502 60C176.649 60 188.517 61.1242 199.996 63.2683Z"
      fill="url(#paint1_radial_213_904)"
      fillOpacity={iconFill === 'brand' ? 0.5 : 0}
    />
    <defs>
      <linearGradient id="paint0_linear_213_904" x1="182.5" y1="20" x2="42.5" y2="166" gradientUnits="userSpaceOnUse">
        <stop offset="0.151666" stopColor="#706ADC" />
        <stop offset="1" stopColor="#543CE9" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_213_904"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(130.499 146.5) rotate(-24.4625) scale(127.989 170.46)"
      >
        <stop stopColor="white" stopOpacity="0.4" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </>
)

/**
 *
 * @param {{fill: Omit<ColorVariant, 'brand'> | undefined}} props
 * @returns {JSX.Element} Renders logo text only.
 */
const LogoText = ({ fill = 'black' }) => (
  <>
    <path
      d="M105.008 116.29V69.1038C105.008 57.7472 99.9796 51.9223 90.9705 51.9223C81.9614 51.9223 76.4722 57.7472 76.4722 69.1038V116.29H52.5038V69.1038C52.5038 57.7472 47.4755 51.9223 38.4664 51.9223C29.4573 51.9223 23.9681 57.7472 23.9681 69.1038V116.29H-0.000244141V55.6938C-0.000244141 42.4515 10.7268 31.7235 23.9681 31.7235V43.7087C29.4993 36.6265 36.7484 30.1311 48.9002 30.1311C59.9625 30.1311 68.3011 35.0341 72.7009 43.541C80.1177 34.8665 88.9592 30.1311 100.482 30.1311C118.291 30.1311 129.018 40.8591 129.018 61.2254V116.29H105.05H105.008Z"
      fill={brandColorMap[fill]}
    />
    <path
      d="M196.527 115.759V106.623C190.702 113.077 182.657 117.351 170.966 117.351C155.043 117.351 141.928 108.216 141.928 91.4952V91.16C141.928 72.6794 155.965 64.1724 175.995 64.1724C184.501 64.1724 190.661 65.5972 196.653 67.6506V66.2258C196.653 56.2941 190.493 50.7625 178.509 50.7625C169.374 50.7625 162.879 52.4806 155.169 55.3303L149.177 37.0173C158.479 32.9105 167.614 30.2285 181.987 30.2285C208.176 30.2285 219.699 43.8061 219.699 66.6868V115.759H196.527ZM197.03 81.6892C192.923 79.8034 187.56 78.5462 181.735 78.5462C171.469 78.5462 165.184 82.653 165.184 90.238V90.5733C165.184 97.0268 170.547 100.84 178.257 100.84C189.445 100.84 197.03 94.6801 197.03 86.0055V81.7311V81.6892Z"
      fill={brandColorMap[fill]}
    />
    <path
      d="M283.765 55.2316C267.842 55.2316 258.037 64.87 258.037 85.0687V116.289H234.068V31.7223H258.037V48.7781C262.939 37.0863 270.817 29.5432 285.022 30.1718V55.2735H283.765V55.2316Z"
      fill={brandColorMap[fill]}
    />
    <path
      d="M348.572 116.518L326.489 81.9457L318.109 90.7879V116.518H294.141V1.36035H318.109V62.7528L346.184 31.9937H374.887L342.706 65.2671L375.977 116.56H348.53L348.572 116.518Z"
      fill={brandColorMap[fill]}
    />
    <path
      d="M458.834 82.388H400.003C402.349 93.2836 409.934 98.9409 420.661 98.9409C428.706 98.9409 434.531 96.4265 441.151 90.2663L454.895 102.419C447.018 112.183 435.662 118.176 420.326 118.176C394.933 118.176 376.16 100.366 376.16 74.4677V74.1325C376.16 49.9946 393.34 30.1311 417.979 30.1311C446.221 30.1311 459.169 52.048 459.169 76.0602V76.3954C459.169 78.7422 459.002 80.167 458.834 82.388ZM417.979 49.4079C408.048 49.4079 401.553 56.49 399.668 67.3856H435.788C434.363 56.6577 428.078 49.4079 417.979 49.4079Z"
      fill={brandColorMap[fill]}
    />
    <path
      d="M500.949 117.715C486.283 117.715 476.352 111.89 476.352 92.3199V52.2577H466.254V31.7656H476.352V10.1421H500.321V31.7656H520.183V52.2577H500.321V88.3807C500.321 93.9123 502.667 96.5943 508.073 96.5943C512.473 96.5943 516.411 95.5048 519.889 93.5771V112.812C514.861 115.829 508.995 117.715 500.949 117.715Z"
      fill={brandColorMap[fill]}
    />
    <path
      d="M572.762 20.2406C567.231 20.2406 564.089 23.0903 564.089 29.3762V32.6867H583.49V52.2569H564.382V116.331H540.414V52.2569H530.483V32.5191H540.414V27.1551C540.414 8.54884 549.716 0 566.603 0C574.187 0 579.215 0.96384 583.657 2.34674V22.2102C580.179 20.953 576.869 20.1568 572.762 20.1568V20.2406Z"
      fill={brandColorMap[fill]}
    />
    <path
      d="M647.856 116.288V104.303C642.325 111.385 635.244 117.88 623.092 117.88C604.948 117.88 594.389 105.895 594.389 86.4925V31.7632H618.357V78.9494C618.357 90.306 623.721 96.1309 632.855 96.1309C641.99 96.1309 647.856 90.306 647.856 78.9494V31.7632H671.825V116.33H647.856V116.288Z"
      fill={brandColorMap[fill]}
    />
    <path
      d="M767.28 82.388H708.448C710.795 93.2836 718.379 98.9409 729.106 98.9409C737.152 98.9409 742.976 96.4265 749.597 90.2663L763.341 102.419C755.463 112.183 744.108 118.176 728.771 118.176C703.378 118.176 684.606 100.366 684.606 74.4677V74.1325C684.606 49.9946 701.786 30.1311 726.425 30.1311C754.667 30.1311 767.615 52.048 767.615 76.0602V76.3954C767.615 78.7422 767.447 80.167 767.28 82.388ZM726.425 49.4079C716.494 49.4079 709.999 56.49 708.113 67.3856H744.233C742.809 56.6577 736.481 49.4079 726.425 49.4079Z"
      fill={brandColorMap[fill]}
    />
    <path
      d="M781.943 116.289V25.1011C781.943 11.8588 792.67 1.13086 805.912 1.13086V116.289H781.943Z"
      fill={brandColorMap[fill]}
    />
  </>
)

/**
 *
 * @param {{fill: ColorVariant | undefined}} props
 * @returns {JSX.Element} Renders logo icon only.
 */
const LogoIcon = ({ fill = 'brand' }) => {
  return (
    <>
      <rect
        width="200"
        height="200"
        rx="40"
        fill={fill === 'brand' ? 'url(#paint0_linear_213_921)' : brandColorMap[fill]}
      />
      <path
        d="M123.079 65.423C124.295 45.5595 131.711 39.4412 141.894 30.2638C114.867 28.1685 89.683 38.645 71.2458 58.5504C59.9321 70.7031 52.1382 86.0408 48.7022 102.3C46.0623 114.956 46.188 127.612 51.2583 139.639C56.6637 152.504 68.6898 168.386 83.5652 170.356C80.8415 167.506 78.2017 164.07 77.2798 162.771C75.1428 159.795 73.341 156.569 71.8744 153.216C63.7872 134.652 66.5946 109.843 86.3727 100.037C85.0737 106.7 88.4678 113.489 96.9322 115.626C97.0998 102.007 105.397 90.1056 118.847 86.9627C117.213 87.3398 115.872 93.081 115.579 94.4639C115.034 97.104 115.202 99.7859 115.579 102.426C116.459 109.047 119.476 113.07 122.786 118.811C127.437 126.899 128.946 136.789 126.474 145.841C124.001 154.892 116.794 163.483 110.09 170.356C123.834 169.685 135.189 159.628 141.349 147.852C145.539 139.932 146.964 130.587 146.838 121.703C146.671 109.927 139.547 102.133 136.153 91.4466C132.298 79.252 138.416 68.0211 146.88 60.1009C146.88 60.1009 142.606 59.3466 142.019 59.3047C139.882 59.0951 137.745 59.1371 135.65 59.4304C132.089 59.9333 125.133 61.6514 123.079 65.5068V65.423Z"
        fill={fill === 'white' ? brandColorMap.black : brandColorMap.white}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M199.996 63.2683C200 65.4187 200 67.6606 200 70V130C200 158.089 200 172.134 193.259 182.223C190.34 186.59 186.59 190.34 182.223 193.259C172.134 200 158.089 200 130 200H70C41.9107 200 27.8661 200 17.7771 193.259C13.4095 190.34 9.65951 186.59 6.74117 182.223C2.71564 176.198 1.09396 168.763 0.440674 157.769C29.5586 99.9672 92.0763 60 164.502 60C176.649 60 188.517 61.1242 199.996 63.2683Z"
        fill="url(#paint1_radial_213_921)"
        fillOpacity={fill === 'brand' ? 0.5 : 0}
      />
      <defs>
        <linearGradient id="paint0_linear_213_921" x1="182.5" y1="20" x2="42.5" y2="166" gradientUnits="userSpaceOnUse">
          <stop offset="0.151666" stopColor="#706ADC" />
          <stop offset="1" stopColor="#543CE9" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_213_921"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(130.499 146.5) rotate(-24.4625) scale(127.989 170.46)"
        >
          <stop stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
      </defs>
    </>
  )
}

const MFLogo = forwardRef(({ variant = 'full', iconFill = 'brand', textFill = 'black', size, ...props }, ref) => {
  const viewBox = useMemo(() => {
    switch (variant) {
      case 'full':
        return '0 0 1056 200'
      case 'icon':
        return '0 0 200 200'
      case 'text':
        return '0 0 806 119'
      default:
        return '0 0 1056 200'
    }
  }, [variant])

  const logoRender = useMemo(() => {
    switch (variant) {
      case 'full':
        return <FullLogo iconFill={iconFill} textFill={textFill} />
      case 'icon':
        return <LogoIcon fill={iconFill} />
      case 'text':
        return <LogoText fill={textFill} />
      default:
        return <FullLogo iconFill={iconFill} textFill={textFill} />
    }
  }, [variant, iconFill, textFill])

  return (
    <svg width={size ?? '100%'} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      {logoRender}
    </svg>
  )
})

MFLogo.displayName = 'MFLOGO'
MFLogo.propTypes = propTypes
MFLogo.defaultProps = defaultProps

export default MFLogo
