import React, { useEffect, useState } from 'react'
import { resetAdminPassword, verifyPasswordToken } from '../../actions' // Adjust to your specific action
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, CardGroup, Card, CardBody, Button } from 'reactstrap'
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation'
import MFLogo from '../logo'
// import logo from '../../assets/img/brand/market-fuel-login.png'

const ResetPasswordForm = props => {
  const [authtoken] = useState(props.match.params.token)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(verifyPasswordToken(authtoken))
  }, [dispatch, authtoken])

  const charLength = { regex: '15,30', field: 'between 15 - 30' }
  const passwordRegex = new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*_-])(?=.*?[0-9]).{${charLength.regex}}$`)

  const handleSubmit = (e, values) => {
    e.preventDefault()
    const { newPassword, confirmPassword } = values

    // Check if the newPassword matches the confirmPassword and exit if they don't match
    if (newPassword !== confirmPassword) {
      toastr.error('Error', 'Your new password and confirm new password do not match')
      return
    }

    dispatch(resetAdminPassword(authtoken, newPassword))
  }

  return (
    <div className="app flex-row align-items-center login-container">
      <Container>
        <Row className="login-page justify-content-center align-items-center">
          <Col md="8">
            <CardGroup>
              <Card className="text-white py-5 d-md-down-none login-card" style={{ width: '44%' }}>
                <CardBody className="d-flex justify-content-center align-items-center login-card">
                  <div className="admin_login_logo">
                    <MFLogo variant="icon" />
                  </div>
                </CardBody>
              </Card>
              <Card className="p-2 login-card">
                <CardBody>
                  <h1>Reset your password</h1>
                  <p className="text-muted">Enter your new password</p>
                  <AvForm onValidSubmit={handleSubmit}>
                    <AvGroup className="mb-2">
                      <AvField
                        name="newPassword"
                        type="password"
                        placeholder="New Password"
                        className="sign-in-input"
                        validate={{
                          required: { value: true, errorMessage: 'Please enter the new password' },
                          pattern: {
                            value: passwordRegex,
                            errorMessage: `Complexity requirement not met. Length should be ${charLength.field} characters and include: 1 lower case, 1 Upper case, 1 numeric, and 1 special character`,
                          },
                        }}
                      />
                    </AvGroup>
                    <AvGroup className="mb-2">
                      <AvField
                        name="confirmPassword"
                        type="password"
                        className="sign-in-input"
                        placeholder="Confirm New Password"
                        validate={{
                          required: { value: true, errorMessage: 'Please confirm your new password' },
                          match: {
                            value: 'newPassword',
                            errorMessage: 'Passwords must match',
                          },
                        }}
                      />
                    </AvGroup>
                    <Row>
                      <Col>
                        <Button type="submit" className="px-4 full-width-button login-button">
                          Update Password
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ResetPasswordForm
