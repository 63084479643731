import _ from 'lodash';
import { FETCH_PRODUCT_SHQ_PACKING_RULE} from '../actions/types'


export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PRODUCT_SHQ_PACKING_RULE: 
      return action.payload
    default:
      return state;
  }
};