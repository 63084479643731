import _ from 'lodash';
import {  GET_LAST_REMINDER_SENT } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case GET_LAST_REMINDER_SENT:
      // return { ...state, [action.payload && action.payload.id]: action.payload };
      return action.payload;    
    default:
      return state;
  }
};
