import _ from 'lodash';
import { FETCH_SHIPPING_LABEL } from '../actions/types'

const initialState = {
  new_label: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHIPPING_LABEL:
      return {...state, new_label: action.payload };
    default:
      return state;
  }
};
