import {
  FETCH_SHIPMENT_STATUS,
} from '../actions/types';

export default(state = [], action) => {
switch(action.type){
    case FETCH_SHIPMENT_STATUS:
      return {...state, [action.id]: action.payload}
    default:
    return state;
}
}