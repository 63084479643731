import _ from 'lodash';
import { FETCH_PORTAL_DOMAINS, FETCH_DOMAIN, CREATE_DOMAIN, EDIT_DOMAIN, DELETE_DOMAIN } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PORTAL_DOMAINS:
      return action.payload;
    case FETCH_DOMAIN:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_DOMAIN:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_DOMAIN:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_DOMAIN:
      return _.omit(state, action.payload );
    default:
      return state;
  }
};
