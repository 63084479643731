import _ from 'lodash';
import { FETCH_SKU_PREVIEW, CREATE_VARIANT_SKU, CLEAR_CONFIG_WIZARD_FORM } from '../actions/types'

const initialState = {
  potentialSkus: [],
  createdVariantSkus: [],
  existingVariantSkus: [],
}

export default (state = _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case FETCH_SKU_PREVIEW:
      return { ...state, potentialSkus: action.payload.potential_skus }
    case CREATE_VARIANT_SKU:
      return { 
        ...state,
        createdVariantSkus: action.payload.created_product_skus,
        existingVariantSkus: action.payload.existing_product_skus,
      }
    case CLEAR_CONFIG_WIZARD_FORM:
      return _.cloneDeep(initialState)
    default:
      return state;
  }
};