import _ from 'lodash';
import { FETCH_THIRD_PARTY_BILLINGS, CREATE_THIRD_PARTY_BILLING } from '../actions/types'

export default (state = [], action) => {
    switch (action.type) {
        case FETCH_THIRD_PARTY_BILLINGS: 
            return action.payload;
        case CREATE_THIRD_PARTY_BILLING:
            return {...state, [action.payload.id]: action.payload }
        default:
            return state;
    }
};