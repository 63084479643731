import UserDropdown from './UserDropdown'
import OrderErrorsCount from './OrderErrorsCount'

const NavbarUser = (props) => {

  return (
    <>
      <ul className="navbar-nav navbar-expand flex-grow-1 align-items-center">
        <OrderErrorsCount />
      </ul>
      <ul className="navbar-nav navbar-expand ml-auto">
        <UserDropdown {...props}/>
      </ul>
    </>
  );
}

export default NavbarUser;