import _ from 'lodash'
import {
  FETCH_CUSTOMER_GROUP_RULE,
  CREATE_CUSTOMER_GROUP_RULE,
  EDIT_CUSTOMER_GROUP_RULE,
  FETCH_PORTAL_CUSTOMER_GROUP_RULES,
} from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_GROUP_RULE:
      return { ...state, [action.payload.id]: action.payload }
    case CREATE_CUSTOMER_GROUP_RULE:
      return { ...state, [action.payload.id]: action.payload }
    case EDIT_CUSTOMER_GROUP_RULE:
      return { ...state, [action.payload.id]: action.payload }
    case FETCH_PORTAL_CUSTOMER_GROUP_RULES:
      return action.payload
    default:
      return state
  }
}
