import _ from 'lodash'
import { FETCH_DROPSHIP_BATCHES, FETCH_DROPSHIP_BATCH } from '../actions/types'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_DROPSHIP_BATCHES:
      return Object.assign({}, ...action.payload.map((e) => ({ [e.id]: e })))
    case FETCH_DROPSHIP_BATCH:
      return { ...state, [action.payload.id]: action.payload }
    default:
      return state
  }
}
