const config = {}

export const handleLogout =
  (currentAdmin = {}) =>
    async dispatch => {
      const associatedPortalInfo = currentAdmin.associated_portal_info
      const currentStoreDomain = localStorage.getItem('store_domain')
      const shouldRedirectToStore = currentStoreDomain && currentStoreDomain.length > 0

      const storeUrl =
        associatedPortalInfo && associatedPortalInfo[0]
          ? associatedPortalInfo[0].store_domain
          : currentStoreDomain

      dispatch({ type: 'SET_LOGIN_STATUS', payload: '' })
      dispatch({ type: 'LOGOUT', [config.storageTokenKeyName]: null, [config.storageRefreshTokenKeyName]: null })
      dispatch({ type: 'CLEAR_CURRENT_AGENCY' })

      // ** Remove user, accessToken & refreshToken from localStorage (VUEXY FUNCTION)
      sessionStorage.removeItem('userData')
      sessionStorage.removeItem(config.storageTokenKeyName)
      sessionStorage.removeItem(config.storageRefreshTokenKeyName)

      // ** Remove user, accessToken & refreshToken from localStorage (MF FUNCTION)
      sessionStorage.removeItem('email')
      sessionStorage.removeItem('admin_id')
      sessionStorage.removeItem(config.storageTokenKeyName)

      // Remove store domain from local and session storage
      sessionStorage.removeItem('store_domain')
      localStorage.removeItem('store_domain')

      window.location.href = shouldRedirectToStore ? `${storeUrl}?logout=true` : '/login'
    }
