import _ from 'lodash';
import { FETCH_PRODUCT_SKUS_SORTED } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PRODUCT_SKUS_SORTED:
      // return action.payload
      return { ..._.mapKeys(action.payload, 'id') };
    default:
      return state;
  }
};