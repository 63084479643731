import _ from 'lodash'
import branditRails from '../api/brandit_rails'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import history from '../history'
import { toastr } from 'react-redux-toastr'
import { saveUrlToLocalStorage } from '../utility/Utils'
import { SESSION_ROUTES } from '../Constants'

export const fetchCurrentAgency = () => async dispatch => {
  try {
    const response = await branditRails.get('/agencies/current')
    if (response.data && response.status === 200) {
      dispatch({ type: 'SET_CURRENT_AGENCY', payload: response.data })
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      if (window.location.pathname !== '/login') {
        toastr.error(errorMessage ? errorMessage : 'Session expired. Please login again.')
      }
    }
    throw error
  }
}

export const redirectToLogin = error => {
  console.info('Redirecting to login page')
  const path = window.location.pathname.toString()
  const SessionRoutes = SESSION_ROUTES
  if (SessionRoutes.every(route => !path.includes(route))) {
    saveUrlToLocalStorage()
    history.push('/login')
    history.go(0)
  }
}
