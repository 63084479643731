import moment from 'moment-timezone'

export function DateParser(date, format = 'default') {
  if (date === null || !date) {
    return '-'
  }
  let tz = sessionStorage.getItem('timezone')
  if (!tz || tz === 'null' || tz === 'undefined') {
    tz = moment.tz.guess()
  }
  let d = moment.tz(date, tz)

  //  Convert to switch ?
  if (format === 'default') {
    d = moment(d).format('MMMM Do YYYY, h:mm a')
  } else if (format === 'short') {
    d = moment(d).format('MM/DD/YYYY')
  } else if (format === 'short_with_time') {
    d = moment(d).format('MM/DD/YYYY, h:mm a')
  } else if (format === 'for_filename') {
    d = moment(d).format('MM[_]DD[_]YYYY[_]h[_]mm[_]a')
  }
  return d
}
