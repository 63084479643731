import _ from 'lodash';
import { FETCH_PRODUCT_LOCATIONS} from '../actions/types'


export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PRODUCT_LOCATIONS:
        return { ...state, [action.id]: action.payload };
    default:
      return state;
  }
};
