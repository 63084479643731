import _ from 'lodash';
import {CHECK_SYSTEM_SETTINGS} from '../actions/types';

const initialState = {
  systemSettingsExist: null 
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECK_SYSTEM_SETTINGS:
      return {
        ...state,
        systemSettingsExist: action.payload
      };
    // Add other case statements as needed
    default:
      return state;
  }
};


