import _ from 'lodash'

const initialState = {
  fulfilment_active_sub_order: {},
  // add key here for list
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SUB_ORDER_FOR_FULFILMENT':
      return { ...state, fulfilment_active_sub_order: action.payload }
    case 'CLEAR_FULFILMENT_ACTIVE_SUB_ORDER':
      return { ...state, fulfilment_active_sub_order: {} }
    default:
      return state
  }
}
