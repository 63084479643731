import _ from 'lodash';
import {  SEND_BALANCE_REMINDERS } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case SEND_BALANCE_REMINDERS:
      return action.payload;    
    default:
      return state;
  }
};
