import { FETCH_ORDER_ERRORS_COUNT } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_ORDER_ERRORS_COUNT:
      return action.payload;
    default:
      return state;
  }
};
