import _ from 'lodash'
import {
  FETCH_CLIENT_PRODUCTS,
  FETCH_PRODUCTS,
  CREATE_PRODUCT,
  FETCH_PRODUCT,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  VALIDATE_PRODUCT,
  FETCH_PORTAL_PRODUCTS,
  FETCH_LOCATION_PRODUCTS,
  ADD_LOCATION_PRODUCTS,
  FETCH_CATEGORY_PRODUCTS,
  FETCH_VARIANT_PRODUCTS,
  UPDATE_PORTAL_PRODUCTS,
  FETCH_BUNDLE_PRODUCTS,
} from '../actions/types'

export default (
  state = {
    client_products: [],
    portal_products: [],
    location_products: [],
    category_products: [],
    variant_products: [],
    bundle_products: [],
    new_product: {},
  },
  action
) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return action.payload
    case FETCH_CLIENT_PRODUCTS:
      return { ...state, ['client_products']: action.payload }
    case FETCH_PORTAL_PRODUCTS:
      return { ...state, ['portal_products']: action.payload }
    case UPDATE_PORTAL_PRODUCTS:
      return state
    case FETCH_LOCATION_PRODUCTS:
      return { ...state, ['location_products']: action.payload }
    case ADD_LOCATION_PRODUCTS:
      return { ...state, ['location_products']: action.payload }
    case FETCH_CATEGORY_PRODUCTS:
      return { ...state, ['category_products']: action.payload }
    case FETCH_PRODUCT:
      return { ...state, [action.payload.id]: action.payload }
    case CREATE_PRODUCT:
      return { ...state, ['new_product']: action.payload }
    case EDIT_PRODUCT:
      return { ...state, [action.payload.id]: action.payload }
    case VALIDATE_PRODUCT:
      return { ...state, [action.payload.id]: action.payload }
    case DELETE_PRODUCT:
      return _.omit(state, action.payload)
    case FETCH_VARIANT_PRODUCTS:
      return { ...state, variant_products: action.payload }
    case FETCH_BUNDLE_PRODUCTS:
      return { ...state, ['bundle_products']: action.payload }
    default:
      return state
  }
}
