import { createContext, useContext } from "react"

import {
  MFAdminLayoutSkin,
  MFAdminLayoutRouterTransition,
  MFAdminLayoutContentWidth,
  MFAdminLayoutNavbarType,
  MFAdminLayoutNavbarBackgroundColor,
  MFAdminLayoutFooterType
} from './MFAdminAppInitializer.types'
import { localStorageUtility } from "../../utility/Utils"

/**
 * Initial Values
 */

/** @type {() => import('./MFAdminAppInitializer.types').MFAdminLayoutSkinType} */
const initialLayoutSkin = () => {
  // const browserPreference = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  const localPreference = localStorageUtility('get')('skin')

  const value = localPreference || /* browserPreference || */ MFAdminLayoutSkin.LIGHT

  localStorageUtility('set')('skin', value)

  return value
}

/** @type {import('./MFAdminAppInitializer.types').MFAdminLayoutRouterTransitionType} */
const initialLayoutRouterTransition = MFAdminLayoutRouterTransition.FADE_IN

/** @type {import('./MFAdminAppInitializer.types').MFAdminLayoutContentWidthType} */
const initialLayoutContentWidth = MFAdminLayoutContentWidth.FULL

/** @type {import('./MFAdminAppInitializer.types').MFAdminLayoutMenu} */
const initialLayoutMenu = {
  isHidden: false,
  isCollapsed: false
}

/** @type {import('./MFAdminAppInitializer.types').MFAdminLayoutNavbarTypeType} */
const initialLayoutNavbarType = MFAdminLayoutNavbarType.STICKY

/** @type {import('./MFAdminAppInitializer.types').MFAdminLayoutNavbarBackgroundColorType} */
const initialLayoutNavbarBackgroundColor = MFAdminLayoutNavbarBackgroundColor.WHITE

/** @type {import('./MFAdminAppInitializer.types').MFAdminLayoutNavbar} */
const initialLayoutNavbar = {
  type: initialLayoutNavbarType,
  backgroundColor: initialLayoutNavbarBackgroundColor
}

/** @type {import('./MFAdminAppInitializer.types').MFAdminLayoutFooterTypeType} */
const initialLayoutFooterType = MFAdminLayoutFooterType.STATIC

/** @type {import('./MFAdminAppInitializer.types').MFAdminLayoutFooter} */
const initialLayoutFooter = {
  type: initialLayoutFooterType
}

/** @type {import('./MFAdminAppInitializer.types').MFAdminLayout} */
const initialLayout = {
  isRTL: false,
  skin: initialLayoutSkin(),
  routerTransition: initialLayoutRouterTransition,
  contentWidth: initialLayoutContentWidth,
  menu: initialLayoutMenu,
  navbar: initialLayoutNavbar,
  footer: initialLayoutFooter,
  customizer: false,
  scrollTop: true
}

/** @type {import('./MFAdminAppInitializer.types').MFAdminEnvType} */
const initialEnv = undefined

/** @type {import('./MFAdminAppInitializer.types').MFAdminInitializerContextState} */
const initialState = {
  isInitialized: false,
  env: initialEnv,
  layout: initialLayout
}

const emptyAction = () => null

/** @type {import('./MFAdminAppInitializer.types').MFAdminLayoutMenuActions} */
const initialLayoutMenuActions = {
}

/** @type {import('./MFAdminAppInitializer.types').MFAdminLayoutNavbarActions} */
const initialNavbarActions = {
}

/** @type {import('./MFAdminAppInitializer.types').MFAdminLayoutFooterActions} */
const initialFooterActions = {
}

/** @type {import('./MFAdminAppInitializer.types').MFAdminLayoutActions} */
const initialLayoutActions = {
  SET_RTL: emptyAction,
  SET_SKIN: emptyAction,
  SET_ROUTER_TRANSITION: emptyAction,
  SET_CONTENT_WIDTH: emptyAction,
  SET_MENU_HIDDEN: emptyAction,
  SET_MENU_COLLAPSED: emptyAction,
  SET_NAVBAR_TYPE: emptyAction,
  SET_NAVBAR_BACKGROUND_COLOR: emptyAction,
  SET_FOOTER_TYPE: emptyAction,
  SET_CUSTOMIZER: emptyAction,
  SET_SCROLL_TOP: emptyAction
}

/** @type {import('./MFAdminAppInitializer.types').MFAdminInitializerContextActions} */
const initialActions = {
  layout: initialLayoutActions
}

/** @type {import('./MFAdminAppInitializer.types').MFAdminInitializerContext} */
export const initialContext = {
  state: initialState,
  actions: initialActions
}

/**
 * Context and Hook Creation
 */

export const MFAdminInitializerStateContext = createContext(initialContext.state)
export const MFAdminInitializerActionsContext = createContext(initialContext.actions)

/**
 * Custom hook to access the initializer context.
 * @returns {import('./MFAdminAppInitializer.types').MFAdminInitializerContext} The current state and actions of the initializer context.
 */
export function useMFAdminAppInitializer() {
  const state = useContext(MFAdminInitializerStateContext)
  const actions = useContext(MFAdminInitializerActionsContext)

  return { state, actions }
}
