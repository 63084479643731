import { CREATE_GOODS_RECEIPT, FETCH_GOODS_RECEIPT_LOGS } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case CREATE_GOODS_RECEIPT:
      return {...state, ["new_goods_receipt"]: action.payload};
      default:
      return state;
  }
};
