import {
    FETCH_SHQ_BOXES,
} from '../actions/types';

export default(state = [], action) => {
switch(action.type){
    case FETCH_SHQ_BOXES:
    return action.payload
    default:
    return state;
}
}