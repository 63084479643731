import _ from 'lodash';
import { FETCH_MENU_ITEM, FETCH_MENU_ITEMS, CREATE_MENU_ITEM, UPDATE_MENU_ITEM, DELETE_MENU_ITEM, BULK_UPDATE_MENU_ITEMS } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_MENU_ITEM:
      return action.payload;
    case BULK_UPDATE_MENU_ITEMS:
      return action.payload;
    case FETCH_MENU_ITEMS:
        return { ...state, ..._.mapKeys(action.payload, 'id') };
    case CREATE_MENU_ITEM:
      return { ...state, [action.payload.id]: action.payload };
    case UPDATE_MENU_ITEM:
      return { ...state, [action.payload.id]: action.payload };  
    case DELETE_MENU_ITEM:
      return _.omit(state, action.payload );
    default:
      return state;
  }
};
