import _ from 'lodash';
import {  FETCH_PORTAL_TAG } from '../actions/types'


export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PORTAL_TAG:
      return { ...state, [action.payload.id]: action.payload}
    default:
      return state;
  }
};
