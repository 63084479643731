import _ from 'lodash'

// user_portals mapped by User ID
const initialState = {
  // 1: [], etc.
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_USER_PORTALS':
      return {
        ...state,
        [action.payload[0]]: action.payload[1]
      }
    default:
      return state
  }
}
