import { store } from '../redux/storeConfig/store';

// inital props will be merged with each new alert, acting as the "default"

// use this as the default function for confirm button; it simply clears the alert
async function onCancel() {
  store.dispatch({type: 'CLEAR_SWEETALERT'})
}

// use this to append clear alert to the confirm function
function onConfirm(incomingCallback) {
  if (incomingCallback) {
    incomingCallback()
  }
  onCancel()
}

const sweetalertInitialProps = {
  show: false,  // main display toggle
  onCancel: onCancel,
  onConfirm: onCancel,
  showCancel: true, // show or hide the cancel button
  type: 'success',
  alertMessage: '',
  title: '',
  confirmBtnText: 'Confirm',
  closeOnClickOutside: true,
  confirmBtnCssClass: 'sweet-alert-confirm-btn',
  cancelBtnCssClass: 'sweet-alert-cancel-btn',
  inputValue: ''
}
const initialState = sweetalertInitialProps

export default (state=initialState, action) => {
  let incomingProps = action.payload ? action.payload : {}
  switch (action.type) {
    case 'SHOW_SWEETALERT':
      // always merge incoming props with initial state,
      // we do not want any old alert data carrying over
      return Object.assign( {},
        initialState,
        {show: true},
        incomingProps,
        {onConfirm: (inputValue) => onConfirm(() => incomingProps.onConfirm(inputValue))}
      )
    
    case 'CLEAR_SWEETALERT':
      return initialState

    default:
      return state
  }
}