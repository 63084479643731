import _ from 'lodash';
import { FETCH_PROMOTION_RULE} from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PROMOTION_RULE:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
};
