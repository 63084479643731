// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper.menu-hide .misc-wrapper {
  margin-left: 0px;
}

.wrapper.menu-collapsed .misc-wrapper {
  width: calc(100% - 80px);
  margin-left: 80px;
}

.wrapper.menu-expanded .misc-wrapper {
  width: calc(100% - 260px);
  margin-left: 260px;
}

.misc-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.misc-wrapper .misc-inner {
  position: relative;
  max-width: 750px;
}
.misc-wrapper .brand-logo {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 1.75rem;
  left: 1.75rem;
  margin: 0;
}
.misc-wrapper .brand-logo .brand-text {
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/@core/scss/base/pages/page-misc.scss"],"names":[],"mappings":"AAaA;EAEE,gBAAA;AAbF;;AAgBA;EAEE,wBAAA;EACA,iBAAA;AAdF;;AAiBA;EAEE,yBAAA;EACA,kBAAA;AAfF;;AAkBA;EACE,aAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;AAfF;AAiBE;EACE,kBAAA;EACA,gBA7BmB;AAcvB;AAkBE;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,SAAA;AAhBJ;AAiBI;EACE,gBAAA;AAfN","sourcesContent":["// ================================================================================================\n//     File Name: page-misc.scss\n//     Description: Coming Soon pages custom scss\n//     ----------------------------------------------------------------------------------------------\n//     Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template\n//     Author: PIXINVENT\n//     Author URL: http://www.themeforest.net/user/pixinvent\n// ================================================================================================\n\n@import '../bootstrap-extended/variables';\n\n$misc-inner-max-width: 750px;\n\n.wrapper.menu-hide\n.misc-wrapper {\n  margin-left: 0px;\n}\n\n.wrapper.menu-collapsed\n.misc-wrapper {\n  width: calc(100% - 80px);\n  margin-left: 80px;\n}\n\n.wrapper.menu-expanded\n.misc-wrapper {\n  width: calc(100% - 260px);\n  margin-left: 260px;\n}\n\n.misc-wrapper {\n  display: flex;\n  flex-basis: 100%;\n  min-height: 100vh;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n\n  .misc-inner {\n    position: relative;\n    max-width: $misc-inner-max-width;\n  }\n\n  .brand-logo {\n    display: flex;\n    justify-content: center;\n    position: absolute;\n    top: remSizing(2);\n    left: remSizing(2);\n    margin: 0;\n    .brand-text {\n      font-weight: 600;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
