import { FETCH_INVENTORY_LOG } from '../actions/types'

const initialState = {
  inventoryLog: [],
}

const inventoryLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVENTORY_LOG:
      return { 
        ...state, 
        inventoryLog: action.payload,  
      }
    default:
      return state
  }
}

export default inventoryLogReducer