import _ from 'lodash'
import {
  CREATE_LOCATION,
  FETCH_LOCATION,
  EDIT_LOCATION,
  DELETE_LOCATION,
  BULK_UPDATE_LOCATIONS,
  FETCH_LOCATIONS,
  FETCH_CLIENT_LOCATIONS,
} from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_LOCATIONS:
      return { ...state, ..._.mapKeys(action.payload, 'id') }
    case FETCH_LOCATION:
      return { ...state, [action.payload.id]: action.payload }
    case CREATE_LOCATION:
      return { ...state, [action.payload.id]: action.payload }
    case EDIT_LOCATION:
      return { ...state, [action.payload.id]: action.payload }
    case DELETE_LOCATION:
      return _.omit(state, action.payload)
    case BULK_UPDATE_LOCATIONS:
      return action.payload
    case FETCH_CLIENT_LOCATIONS:
      return action.payload
    default:
      return state
  }
}
