// reducer to manage various loading states.
const initialState = {
  liac: false,
  bulkTags: false,
  bulkPrint: false,
  session: true,
  admin: true
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      const [key, val] = action.payload
      return {
        ...state,
        [key]: val
      }
    default:
      return state
  }
}