import _ from 'lodash';
import { FETCH_DROPSHIP_IMAGES, DELETE_DROPSHIP_IMAGE, EDIT_DROPSHIP_IMAGE, CREATE_DROPSHIP_IMAGE } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case CREATE_DROPSHIP_IMAGE: 
      return {..._.mapKeys(state, 'id'), [action.payload.id]: action.payload};
    case FETCH_DROPSHIP_IMAGES:
      return {..._.mapKeys(state, 'id'), ..._.mapKeys(action.payload, 'id')};
    case EDIT_DROPSHIP_IMAGE:
      return {..._.mapKeys(state, 'id'), [action.payload.id]: action.payload};
    case DELETE_DROPSHIP_IMAGE:
      return _.omit(state, action.payload );
    default:
      return state;
  }
};
