import * as Types from './types'
import brandit_rails from '../api/brandit_rails'
import { addAuthHeadersFromSessionStorage } from '../api/brandit_rails'
import history from '../history'
import { saveUrlToLocalStorage } from '../utility/Utils'

export const createSession = values => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  dispatch({ type: 'SET_LOGIN_STATUS', payload: { message: 'LOADING' } })

  try {
    const response = await brandit_rails.post('/sessions', values)

    if (response.status !== 201) {
      handleErrorResponse(response, values, dispatch)
      return
    }

    const jwtToken = response.data

    if (!jwtToken) {
      console.error('No JWT token received from server.')
      dispatch({ type: Types.CREATE_SESSION_ERROR, payload: { message: 'Agency not found' } })
      return
    }
    dispatch({ type: 'CREATE_SESSION', payload: jwtToken })
    dispatch({ type: 'SET_LOGIN_STATUS', payload: { message: 'SUCCESS' } })

    // Redirect to dashboard
    const destinationUrl = localStorage.getItem('redirectUrl') || '/dashboard'
    localStorage.removeItem('redirectUrl')
    history.push(destinationUrl)
    history.go(0)
  } catch (error) {
    handleErrorResponse(error, values, dispatch)
  }
}

const handleErrorResponse = (error, values, dispatch) => {
  let errorData = { message: 'An unexpected error occurred.' }
  // if error is from server
  if (error?.response) {
    // handle specific status codes
    switch (error?.response?.status) {
      case 401:
        // handle error if request was using auth0
        if (values.auth0Token) {
          errorData = {
            message:
              "We don't have an account associated with this email address. Please contact an administrator to create an account for you.",
          }
          dispatch({ type: Types.CREATE_SESSION_ERROR, payload: errorData })
          setTimeout(() => {
            saveUrlToLocalStorage()
            history.push('/login')
            history.go(0)
          }, 5000)
          return
        } else {
          // for all other 401 errors
          errorData = getErrorMessageFor401(error.response)
          break
        }
      case 422:
        if (response.data.base) {
          errorData = { message: response.data.base[0] }
        }
        return { message: 'Email or password invalid.' }
      default:
        // error message remains as default
        break
    }
  } else {
    // all other errors from server
    console.error('An unexpected error occurred.', error)
  }

  dispatch({ type: Types.CREATE_SESSION_ERROR, payload: errorData })
  dispatch({ type: 'SET_LOGIN_STATUS', payload: { message: 'FAILED' } })
}

const getErrorMessageFor401 = response => {
  let extraMessage = ''
  switch (response.data.error_code) {
    case 'black_listed':
      return { message: 'Not Authorized IP.' }
    case 'not_active':
      extraMessage = response.data.message ? response.data.message : ''
      return { message: 'Your account is not active. ' + extraMessage }
    case 'token_required':
      extraMessage = response.data.message ? response.data.message : ''
      return { message: 'Please enter 2 factor authentication Token. ' + extraMessage, action: 'token_required' }
    default:
      return { message: 'Email or password invalid.' }
  }
}

export const clearLoginStatus = () => async dispatch => {
  dispatch({ type: 'CLEAR_LOGIN_STATUS' })
}

export const setSessionToken = token => async dispatch => {
  dispatch({ type: 'CREATE_SESSION', payload: token })
}
