import _ from 'lodash';
import { FETCH_CUSTOMER_GROUP, FETCH_CUSTOMER_GROUPS, CREATE_CUSTOMER_GROUP } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_GROUP:
      return { ...state, [action.payload.id]: action.payload };
    case FETCH_CUSTOMER_GROUPS:
      return action.payload;
    case CREATE_CUSTOMER_GROUP:
      return action.payload;
    default:
      return state;
  }
};
