import { useState, useEffect } from 'react'
import './ee.css'

const useKonamiCode = () => {
  const konamiCode = [
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowLeft',
    'ArrowRight',
    'KeyB',
    'KeyA',
  ]
  const [userKeys, setUserKeys] = useState([])

  const onComplete = () => {
    let elements = Array.from(document.querySelectorAll('*'))

    // Filter out non-visible and larger elements (optional)
    let smallElements = elements.filter(element => element.offsetWidth * element.offsetHeight < 10000)
    let largeElements = elements.filter(element => element.offsetWidth * element.offsetHeight >= 10000).reverse()

    smallElements = shuffle(smallElements)
    smallElements.forEach((row, index) => {
      const delay = index * 0.1 // adjust this value to increase or decrease the delay
      row.style.animation = `shrinkAndDisappear 2s ease-in-out ${delay}s forwards`
    })
    const baseDelay = smallElements.length * 0.1 // Total delay time for small elements animation
    largeElements.forEach((row, index) => {
      const delay = baseDelay + index * 0.2 // adjust this value to increase or decrease the delay
      row.style.animation = `shrinkAndDisappear 2s ease-in-out ${delay}s forwards`
    })
  }

  useEffect(() => {
    const handleKeyDown = e => {
      // if string is too long, reset it
      if (userKeys.length > 500) {
        setUserKeys([])
        return
      }
      setUserKeys(prevKeys => [...prevKeys, e.code].slice(-konamiCode.length))
    }
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    if (JSON.stringify(userKeys) === JSON.stringify(konamiCode)) {
      onComplete()
      setUserKeys([])
    }
  }, [userKeys, konamiCode])
}

// Fisher-Yates Shuffle
const shuffle = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export default useKonamiCode
