import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'
import { Auth0Provider } from '@auth0/auth0-react'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import './index.css'
import { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_REDIRECT_URI } from './Constants'

// OLD PROJECT IMPORTS
import ReduxToastr from 'react-redux-toastr'

// const LazyApp = lazy(() => import('./App'))
import App from './App'
import LoadingSpinner from './components/general/LoadingSpinner'
import {MFAdminAppInitializerProvider} from './contexts/MFAdminAppInitializer'

if (module.hot) {
  module.hot.accept()
}

const auth0Domain = REACT_APP_AUTH0_DOMAIN
const auth0ClientId = REACT_APP_AUTH0_CLIENT_ID
const auth0RedirectUri = REACT_APP_AUTH0_REDIRECT_URI

const appRootContainer = document.getElementById('root')
const root = createRoot(appRootContainer)

root.render(
  <MFAdminAppInitializerProvider>
    <Provider store={store}>
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        redirect_uri={auth0RedirectUri}
      >
        <ReduxToastr
          timeOut={3000}
          newestOnTop={false}
          preventDuplicates
          position="top-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <ThemeContext>
          <App />
          <ToastContainer newestOnTop />
        </ThemeContext>
      </Auth0Provider>
    </Provider>
  </MFAdminAppInitializerProvider>
)
