import {FETCH_SHQ_SHIPPING_GROUPS, 
        } from '../actions/types';

    export default(state = [], action) => {
        switch(action.type){
            case FETCH_SHQ_SHIPPING_GROUPS: 
            return action.payload
            default:
            return state;
        }
    }