import _ from "lodash";
import { FETCH_ORDER, UPDATE_ORDER } from "../actions/types";

const initialState = {
  order: {},
  order_items: [],
  breakable_bundles: [],
  // unbreakable_order_items: [],
  customer: {
    id: "",
    email: "",
    full_name: "",
    ecpid: "",
    eclid: "",
  },
  // order_deduction_summary: [],
  promotion_orders: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER:
      return action.payload;
    case UPDATE_ORDER:
      return {
        ...state,
        order: action.payload
      };
    default:
      return state;
  }
};
