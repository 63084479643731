import * as Types from '../actions/types'

const transformLocationsData = (locations, portalChanges, portalId) => {
  return Object.keys(locations).reduce((acc, locationId) => {
    const location = locations[locationId]
    const forceAssigned = portalChanges?.forceAssigned !== undefined ? portalChanges.forceAssigned : location.assigned
    if (location.portal_id === portalId) {
      acc[locationId] = {
        ...location,
        assigned: forceAssigned,
      }
      return acc
    }
    acc[locationId] = {
      ...location,
    }
    return acc
  }, {})
}

const initialState = {
  /**
   * locations
   * @type {Object.<locationGroupId, Object.<locationId, Location>>}
   */
  locations: {},

  /**
   * portals
   * @type {Object.<portalId, Portal>}
   */
  portals: {},

  /**
   * customerGroups
   * @type {
   *  Object.<portalId, {
   *    list: Array<CustomerGroup>,
   *    selected: CustomerGroup,
   *    system_managed: boolean
   *  }>}
   */
  customerGroups: {},
  /**
   * locations
   * @type {Object.<locationGroupId, { data: Object.<locationId, Location>, list: Array<Location> }>}
   */
  locations: {},
  /**
   * refreshLogIButton: Change to true when needing to refetch the log in as customer button
   * @type {boolean}
   */
  refreshLogInButton: false,
  /**
   * portalChanges: Keeps track of portal changes, specifically the assigned toggle so that locations
   * inherit the toggle value of the portal
   * e.g., if portalChanges[portalId].forceAssigned is true, all locations assigned to that portal will be true
   * @type {Object.<portalId, { forceAssigned: boolean }>}
   */
  portalChanges: {},
  /**
   * usersActive: Keeps track of the active status of users
   * @type {Object.<userId, { isActive: boolean }>}
   */
  usersActive: {},
  /**
   * hasAutomation: Indicates if the client the customer belongs to has automation
   * @type {boolean}
   */
  hasAutomation: false,
  /**
   * refreshUserPortalsLocations: increase to trigger prop change/re-render on UserPortalLocationList.js
   * @type {boolean}
   */
  refreshUserPortalsLocations: 0,
  /**
   * ccdpListRefreshCount: increase to trigger prop change/re-render on UserDetailsCcdpList.js
   * @type {boolean}
   */
  ccdpListRefreshCount: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_CUSTOMER_PORTALS:
      return {
        ...state,
        portals: action.payload.portals,
        portalsList: Object.keys(action.payload.portals).map(portalId => action.payload.portals[portalId]),
        hasAutomation: action.payload.has_automation,
      }
    case Types.FETCH_CUSTOMER_CUSTOMER_GROUPS:
      return {
        ...state,
        customerGroups: {
          ...state.customerGroups,
          [action.payload.portalId]: {
            ...state.customerGroups[action.payload.portalId],
            ...action.payload.data,
            selected: {
              ...state.customerGroups[action.payload.portalId]?.selected,
              [action.payload.userId]: action.payload.data.selected,
            },
          },
        },
      }
    case Types.FETCH_CUSTOMER_LOCATIONS:
      return {
        ...state,
        locations: {
          ...state.locations,
          [action.payload.locationGroupId]: {
            data: transformLocationsData(
              action.payload.data,
              state.portalChanges[action.payload.portalId],
              action.payload.portalId
            ),
            list: Object.keys(action.payload.data).map(locationId => action.payload.data[locationId]),
          },
        },
      }
    case Types.SET_SELECTED_CUSTOMER_GROUP:
      return {
        ...state,
        customerGroups: {
          ...state.customerGroups,
          [action.payload.portalId]: {
            ...state.customerGroups[action.payload.portalId],
            selected: {
              ...state.customerGroups[action.payload.portalId]?.selected,
              [action.payload.userId]: action.payload.data,
            },
          },
        },
      }
    case Types.SET_CUSTOMER_ACTIVE: {
      return {
        ...state,
        usersActive: {
          ...state.usersActive,
          [action.payload.userId]: {
            isActive: action.payload.data,
          },
        },
      }
    }
    case Types.FETCH_USER:
    case Types.CREATE_USER: {
      return {
        ...state,
        usersActive: {
          ...state.usersActive,
          [action.payload.id]: {
            isActive: action.payload.is_active,
          },
        },
      }
    }
    case Types.TOGGLE_CUSTOMER_LOCATION: {
      const { locationGroupId, locationId, attribute } = action.payload
      const newLocations = {
        ...state.locations,
        [locationGroupId]: {
          ...state.locations[locationGroupId],
          data: {
            ...state.locations[locationGroupId].data,
            [locationId]: {
              ...state.locations[locationGroupId].data[locationId],
              [attribute]: !state.locations[locationGroupId].data[locationId][attribute],
            },
          },
        },
      }

      const isPortalAssigned = () => {
        const locations = Object.keys(newLocations)
          .map(locationGroupId => {
            return Object.keys(newLocations[locationGroupId].data).map(
              locationId => newLocations[locationGroupId].data[locationId]
            )
          })
          .flat()
          .filter(location => location.portal_id === action.payload.portalId)
        const isAnyLocationAssigned = locations.some(location => location.assigned)
        return isAnyLocationAssigned
      }
      const newAssigned = isPortalAssigned()

      action.payload.callback(newAssigned !== state.portals[action.payload.portalId].assigned, newAssigned)

      return {
        ...state,
        locations: newLocations,
        portals: {
          ...state.portals,
          [action.payload.portalId]: {
            ...state.portals[action.payload.portalId],
            assigned: newAssigned,
          },
        },
      }
    }

    case Types.TOGGLE_CUSTOMER_PORTAL: {
      const { portalId, attribute } = action.payload
      return {
        ...state,
        portals: {
          ...state.portals,
          [portalId]: {
            ...state.portals[portalId],
            [attribute]: !state.portals[portalId][attribute],
            ...(attribute === 'access_approved' ? { assigned: true } : {}),
          },
        },
        portalChanges: {
          ...state.portalChanges,
          [portalId]: {
            ...state.portalChanges[portalId],
            [attribute === 'assigned' ? 'forceAssigned' : attribute]: !state.portals[portalId][attribute],
          },
        },
      }
    }
    case Types.REFRESH_LOGIN_BUTTON: {
      return {
        ...state,
        refreshLogInButton: action.payload,
      }
    }
    case Types.SET_ALL_LOCATIONS_ASSIGNED: {
      const changedLocations = []
      const locationsLevel = (acc, locationId, locationGroupId) => {
        const location = state.locations[locationGroupId].data[locationId]
        const newLocation = {
          ...location,
          assigned: location.portal_id === action.payload.portalId ? action.payload.isAssigned : location.assigned,
        }
        if (newLocation.assigned !== location.assigned) {
          changedLocations.push(newLocation)
        }
        acc[locationId] = newLocation
        return acc
      }
      const locationGroupsLevel = (acc, locationGroupId) => {
        acc[locationGroupId] = {
          ...state.locations[locationGroupId],
          data: Object.keys(state.locations[locationGroupId].data).reduce(
            (acc, locationId) => locationsLevel(acc, locationId, locationGroupId),
            {}
          ),
        }
        return acc
      }
      const newLocations = Object.keys(state.locations).reduce(locationGroupsLevel, {})
      action.payload.callback(changedLocations)
      /** { [locationGroupId]: { [locationId]: Location } } */
      return {
        ...state,
        locations: {
          ...state.locations,
          ...newLocations,
        },
      }
    }
    case Types.CLEAR_USER_LOCATIONS: {
      return {
        ...initialState,
        usersActive: {
          ...state.usersActive,
        },
      }
    }
    case Types.SET_CUSTOMER_GROUP_SYSTEM_MANAGED: {
      return {
        ...state,
        customerGroups: {
          ...state.customerGroups,
          [action.payload.portalId]: {
            ...state.customerGroups[action.payload.portalId],
            system_managed: action.payload.systemManaged,
          },
        },
      }
    }
    case Types.REFRESH_PORTALS_LOCATIONS: {
      return {
        ...state,
        refreshUserPortalsLocations: state.refreshUserPortalsLocations + 1,
      }
    }

    case Types.CCDP_LIST_REFRESH_COUNT: {
      return {
        ...state,
        ccdpListRefreshCount: state.ccdpListRefreshCount + 1,
      }
    }

    default:
      return state
  }
}
