import { FETCH_TAG_SETS, CREATE_PORTAL_TAG, EDIT_PORTAL_TAG } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_TAG_SETS:
      return { ...state, [action.id]: action.payload }
    case CREATE_PORTAL_TAG:
      let current = state[action.id]
      current.push(action.payload)
      return { ...state, [action.id]: current }
    default:
      return state
  }
}
