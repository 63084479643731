import _ from 'lodash';
import {  CREATE_PERIOD, FETCH_PERIOD, FETCH_PORTAL_PERIODS, EDIT_PERIOD, SEND_BALANCE_REMINDERS } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PORTAL_PERIODS:
      return action.payload;
    case FETCH_PERIOD:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_PERIOD:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_PERIOD:
      return { ...state, [action.payload.id]: action.payload };
    case SEND_BALANCE_REMINDERS:
      return action.payload;
      
    default:
      return state;
  }
};
