import _ from 'lodash';
import { FETCH_BLACKLISTED_IPS, CREATE_BLACKLISTED_IP, EDIT_BLACKLISTED_IP } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_BLACKLISTED_IPS:
      return action.payload;
    case CREATE_BLACKLISTED_IP:
      return action.payload;
    case EDIT_BLACKLISTED_IP:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
};