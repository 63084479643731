import _ from 'lodash';
import { FETCH_OVERIDDEN_CATEGORY, FETCH_CATEGORY, FETCH_PORTAL_CATEGORIES } from '../actions/types'

const initialState = {
  portal_categories: [],
  location_categories: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOCATION_CATEGORIES':
      return {
        ...state,
        location_categories: action.payload
      }
    case 'UPDATE_CATEGORY':
      const updatedCategory = action.payload
      
      // replace this category in portal and location categories
      const indexOfCatInPortals = _.findIndex(state.portal_categories, { id: updatedCategory.id })
      const indexOfCatInLocations = _.findIndex(state.location_categories, { id: updatedCategory.id })
      
      let newPortalCategories   = _.cloneDeep(state.portal_categories)
      let newLocationCategories = _.cloneDeep(state.location_categories)

      indexOfCatInPortals >= 0   ? newPortalCategories.splice(indexOfCatInPortals, 1, updatedCategory) : null
      indexOfCatInLocations >= 0 ? newLocationCategories.splice(indexOfCatInLocations, 1, updatedCategory) : null
      return {
        portal_categories:   newPortalCategories,
        location_categories: newLocationCategories
      }
    case FETCH_CATEGORY:
      return {...state, single_category: action.payload}
    case FETCH_PORTAL_CATEGORIES:
      return { ...state,
        portal_categories: action.payload
      }
    case FETCH_OVERIDDEN_CATEGORY:
      return { ...state,
        overidden_category: action.payload}
    default:
      return state;
  }
};
