import {ADD_PERMISSIONS, ADMIN_PERMISSIONS,  FULL_ACCESS_GRANTED} from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case ADD_PERMISSIONS:
      return action.payload
    case ADMIN_PERMISSIONS:
      return action.payload
    default:
      return state;
  }
};