import _ from 'lodash';
import { FETCH_CREDIT_BALANCES } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CREDIT_BALANCES:
      return action.payload;
    default:
      return state;
  }
};
