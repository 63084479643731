import React from 'react'
import EnvironmentBanner from '../../../../components/global/EnvironmentBanner'
import NavbarUser from './NavbarUser'
import NavbarBookmarks from './NavbarBookmarks'

const ThemeNavbar = props => {
  const { setMenuVisibility } = props

  return (
    <>
      <div className="bookmark-wrapper d-flex align-items-center">
        <NavbarBookmarks setMenuVisibility={setMenuVisibility} />
        <EnvironmentBanner />
      </div>
      <NavbarUser />
    </>
  )
}

export default ThemeNavbar
