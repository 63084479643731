import { FETCH_GOODS_RECEIPT_LOGS } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_GOODS_RECEIPT_LOGS:
      return {...state, [action.payload.id]: action.payload.logs};
      default:
      return state;
  }
};
