import { ADD_BUNDLE_GROUP,
  CLEAR_BUNDLE_GROUP_PRODUCTS,
  FETCH_BUNDLE_GROUP_PRODUCTS,
  UPDATE_BUNDLE_GROUP_PRODUCT} from '../actions/types'
import _ from "lodash";

export default (state = [], action) => {
  switch (action.type) {
    case ADD_BUNDLE_GROUP:
      let groups = {...state};
      if(!groups[action.payload.bundle_id]){
        groups[action.payload.bundle_id] = {}
      }
      groups[action.payload.bundle_id][action.iter] = action.payload;
      return groups;
    case CLEAR_BUNDLE_GROUP_PRODUCTS:
      return action.payload;
    case FETCH_BUNDLE_GROUP_PRODUCTS:
      return {...state, ['new_bundle_products']: action.payload.bundle_group_products};
    case UPDATE_BUNDLE_GROUP_PRODUCT:
      return _.mapKeys(action.payload, 'id');
    default:
      return state;
  }
};
