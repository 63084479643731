import _ from 'lodash';
import { FETCH_PRODUCT_SKU, FETCH_PRODUCT_SKUS } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PRODUCT_SKU:
      return { ...state, [action.id]: action.payload };
    case FETCH_PRODUCT_SKUS:
      return { ...state, ..._.mapKeys(action.payload, 'id') }
    default:
      return state;
  }
};