import axios from 'axios'
import { Smart } from 'smart-webcomponents-react/grid'
import { REACT_APP_BACKEND_API_URL, REACT_APP_PORTAL_TOKEN } from '../Constants'
import { ADMIN_APP_VERSION } from '../Constants'

Smart.License = '719DD551-2B8A-4E22-A1E6-5C7C3932B99E'

let token = REACT_APP_PORTAL_TOKEN
if (typeof token === 'undefined') {
  // default admin token for development
  token = '45ff8f7bc1342233fe604af8ea84ce471ef4c57db2c91d791346bdbb67adf64c'
}

export const addAuthHeadersFromSessionStorage = axiosInstance => {
  // set up-to-date headers with each request
  axiosInstance.interceptors.request.use(addAuthHeadersToRequestConfig)
}

export const addAuthHeadersToRequestConfig = config => {
  const adminToken = sessionStorage.getItem('admin_token')
  const adminEmail = sessionStorage.getItem('email')
  const authToken = sessionStorage.getItem('agencyAuthToken')

  if (adminToken) {
    config.headers['X-Admin-Token'] = adminToken
  }
  if (adminEmail) {
    config.headers['X-Admin-Email'] = adminEmail
  }
  if (authToken) {
    config.headers['Authorization'] = `Bearer ${authToken}`
  }

  // add app version from package.json to headers
  if (ADMIN_APP_VERSION) {
    config.headers['X-App-Version'] = ADMIN_APP_VERSION
  }

  return config
}

const createAxiosInstance = () => {
  const instance = axios.create({
    baseURL: REACT_APP_BACKEND_API_URL,
    headers: {
      'X-TOKEN': token,
      'x-apikey': '59a7ad19f5a9fa0808f11931',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      Accept: 'application/json',
      withCredentials: true,
    },
  })

  // Add headers for auth from session storage
  addAuthHeadersFromSessionStorage(instance)

  return instance
}

export default createAxiosInstance()

export const branditRailsSkipInterceptors = createAxiosInstance()
