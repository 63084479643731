// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import { createStore, applyMiddleware, compose } from 'redux'
import { NODE_ENV } from '../../Constants'
import reducers from '../../reducers'

// ** init middleware
const middleware = [thunk, createDebounce()]

// Options for Redux Dev Tools
const options = {
  // enables stack trace for actions unless we are in production
  trace: NODE_ENV === 'production' ? false : true,
}

// ** Dev Tools
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const composeEnhancers =
  window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(options) : compose

// ** Create store
const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(...middleware)))

export { store }
