export default (state = {
  total_unique_users: '',
  active_users:       '',
  portals_online:     '',
  new_users:          '',
  net_sales:          '',
  total_orders:       ''
}, action) => {
  switch (action.type) {
    case 'SET_DASHBOARD':
      return action.payload;
    default:
      return state;
  }
};
