import _ from 'lodash'
import { FETCH_USER, FETCH_USERS, DELETE_USER, CREATE_USER, EDIT_USER, FETCH_USER_BY_EMAIL } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_USER:
      return { ...state, [action.payload.id]: action.payload }
    case FETCH_USERS:
      return { ...state, ..._.mapKeys(action.payload, 'id') }
    case CREATE_USER:
      return { ...state, [action.payload.id]: action.payload }
    case EDIT_USER:
      return { ...state, [action.payload.id]: action.payload }
    case DELETE_USER:
      return _.omit(state, action.payload)
    case FETCH_USER_BY_EMAIL:
      return { ...state, [action.payload.email]: action.payload }
    case 'CLEAR_USER':
      return {
        ...state,
        [action.payload]: null,
      }
    default:
      return state
  }
}
