import _ from 'lodash';
import { UPLOAD_HISTORY } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case UPLOAD_HISTORY:
      return   action.payload ;
    default:
      return state;
  }
};
