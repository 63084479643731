import { useEffect } from 'react'
import { APP_VERSION } from '../constants'
import { handleLogout } from '../redux/actions/auth'
import { store } from '../redux/storeConfig/store'
import { toastr } from 'react-redux-toastr'

export const checkAppVersion = () => {
  const currentVersion = APP_VERSION
  const storedVersion = localStorage.getItem('appVersion')

  if (!storedVersion || compareVersions(storedVersion, currentVersion) < 0) {
    // Stored version is older than the current version, log out & reload the page
    toastr.info('A new version of the app is available. Please log in again to access your account.', {
      toastId: 'newAppVersionAvailable',
      timeOut: 5000,
    })

    localStorage.setItem('appVersion', currentVersion)
    // setTimeout(() => {
    //   store.dispatch(handleLogout())
    //   window.location.reload()
    // }, 5000)
  }

  // Update the stored version in localStorage
  localStorage.setItem('appVersion', currentVersion)
}

const useVersionCheck = () => {
  useEffect(() => {
    checkAppVersion()
  }, [])
}

// Helper function to compare semantic versions in the format "5.12.31"
const compareVersions = (v1, v2) => {
  const v1Parts = v1.split('.').map(Number)
  const v2Parts = v2.split('.').map(Number)

  for (let i = 0; i < v1Parts.length; i++) {
    if (v1Parts[i] < v2Parts[i]) {
      return -1
    } else if (v1Parts[i] > v2Parts[i]) {
      return 1
    }
  }

  return 0
}

export default useVersionCheck
