import _ from 'lodash';
import { FETCH_CARTS } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CARTS:
      return { ..._.mapKeys(action.payload, 'user_cart_id') };
    default:
      return state;
  }
};
