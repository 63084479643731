import _ from 'lodash'
import { CREATE_SESSION_ERROR, CLEAR_ERRORS, CREATE_VALIDATION_ERROR } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case CREATE_SESSION_ERROR:
      let formattedErrors = {}
      let i = 0
      let message = ''
      let requiredAction = ''
      for (var key in action.payload) {
        if (key === 'message') {
          message = action.payload[key]
        } else if (key === 'action') {
          requiredAction = action.payload[key]
        }
        i++
      }
      formattedErrors = { message: message, action: requiredAction }
      return formattedErrors
    case CREATE_VALIDATION_ERROR:
      return action.payload
    case CLEAR_ERRORS:
      return []
    default:
      return state
  }
}
