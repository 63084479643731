import React, { Component } from 'react'
import { connect } from 'react-redux';
import ModalDisplay from '../ModalDisplay'
import history from '../../history'
import { deleteAuthorizedAdminEmailDomain } from '../../actions'
import {
  Button,
} from 'reactstrap';

class AuthorizedAdminEmailDomainDelete extends Component {
  renderActions() {
    return (
      <React.Fragment>
        <Button size="sm" color="danger" className="btn-left-space" onClick={() => this.props.deleteAuthorizedAdminEmailDomain(this.props.match.params.id)}> Delete </Button>
        <Button size="sm" outline color="danger" className="btn-left-space" onClick={history.back}> Cancel </Button>
      </React.Fragment>
    );
  }

  renderContent() {
    return (
      <div>
        {this.props.match.params.name}
      </div>
    )
  }

  render() {
    return (
      <ModalDisplay
        title="Are you sure you want to delete this authorized domain?"
        content={this.renderContent()}
        actions={this.renderActions()}
        onDismiss={() => history.back()}
      />
    );
  }
};

export default connect(null, { deleteAuthorizedAdminEmailDomain })(AuthorizedAdminEmailDomainDelete);

// export for testing
export { AuthorizedAdminEmailDomainDelete };