// ** React Imports
import { useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

// ** Third Party Components
import { X } from 'react-feather'

// ** Config
import themeConfig from '../../../../../configs/themeConfig'
import MFLogo from '../../../../../components/logo'
import ReactTooltip from 'react-tooltip'
import { Button } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { handleMenuCollapsed } from '../../../../../redux/actions/layout'
// import { renderTooltip } from '../../../../../components/general/FormInputs'
// import { Tooltip } from 'reactstrap'

const VerticalMenuHeader = props => {
  // ** Props
  const { setMenuVisibility, setGroupOpen, menuHover, menuVisibility } = props

  const dispatch = useDispatch()
  const { menuCollapsed } = useSelector(state => state.layout)

  const toggleMenuCollapsed = useCallback(() => {
    dispatch(handleMenuCollapsed(!menuCollapsed))
  }, [dispatch, menuCollapsed, handleMenuCollapsed])
  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])

  // ** Menu toggler component
  const Toggler = () => {
    return (
      <>
        <ReactTooltip id="sidebar-toggle" place="left" offset={{ bottom: 35, right: 21}} />
        {!menuCollapsed ? (
          <i
            aria-expanded={true}
            data-for="sidebar-toggle"
            data-tip="Collapse Side Bar"
            className="fa-duotone fa-caret-left menu-duotone-icon-8"
            // onClick={() => setMenuCollapsed(true)}
          />
        ) : (
          <i
            aria-expanded={false}
            data-for="sidebar-toggle"
            data-tip="Expand Side Bar"
            className="fa-duotone fa-caret-right menu-duotone-icon-8"
            // onClick={() => setMenuCollapsed(false)}
          />
          )}
      </>
    )
  }
  //34.09

  return (
    <div className="navbar-header">
      {/* <MFLogo variant='full' textFill='white' size={180} /> */}
      <ul className="nav navbar-nav">
        <li className="nav-item mr-auto menulogomg">
          <NavLink to="/" >
            {/* {(!menuCollapsed || menuHover) ? (
                <MFLogo className="brand-logo" variant="full" size={180} textFill='white' />
                ) : (
                <MFLogo className="brand-logo" variant="icon" size={42} textFill='white' />
                )
                  } */}
            <span className="brand-logo">
                <MFLogo className="icon" variant="icon" size={(!menuCollapsed || menuHover || menuVisibility) ? 34.09 : 48} />
              {(!menuCollapsed || menuHover || menuVisibility) && <MFLogo className="text" variant="text" size={180 - 34.09 - 10.5} textFill='white' />}
            </span>

            {/* <h2 className="brand-text mb-0">{themeConfig.app.appName}</h2> */}
          </NavLink>
        </li>
        {(!menuCollapsed || menuHover || menuVisibility) && (
          <li className="nav-toggle togglemg">
            <Button color='link' className="nav-link modern-nav-toggle d-none d-xl-block" onClick={toggleMenuCollapsed}>
              <Toggler />
            </Button>
            <Button color='link' className="nav-link modern-nav-toggle d-block d-xl-none" onClick={() => setMenuVisibility(false)}>
              <X className="toggle-icon icon-x" size={20} />
            </Button>
          </li>
        )}
      </ul>
    </div>
  )
}

export default VerticalMenuHeader
