import { useEffect } from 'react'
import { useMFAdminAppInitializer } from '../../contexts/MFAdminAppInitializer'
import MFLogo from '../logo'

export default function LoadingSpinner() {
  const { actions: { setOverlay } } = useMFAdminAppInitializer()

  useEffect(() => {
    setOverlay(true)
    return () => {
      setOverlay(false)
    }
  }, [setOverlay])

  return (
    <span className='mf-loading-spinner'>
      <MFLogo variant='icon' className='mf-loading-logo' />
    </span>
  )
}
