import _ from 'lodash';
import { EDIT_CLIENT_PRODUCT, FETCH_AGGREGATED_CLIENT_PRODUCT } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_AGGREGATED_CLIENT_PRODUCT:
      return action.payload;
    case EDIT_CLIENT_PRODUCT:
      return { ...state, [action.payload.id]: action.payload };
      // // return { ...state, [action.payload.id]: action.payload };
      // return Object.assign({}, state, {[action.payload.id]: action.payload} );
    default:
      return state;
  }
};
