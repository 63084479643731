import { ADD_BUNDLE, FETCH_BUNDLES, FETCH_BUNDLE, EDIT_BUNDLE, FETCH_ASSOC_BUNDLES } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case ADD_BUNDLE:
      return {...state, ['new_bundle']: action.payload};
    case FETCH_BUNDLES:
      return {
        ...state,   ['bundle_products']: action.payload
    }
    case FETCH_BUNDLE:
      return {
        ...state,   [action.payload.id]: action.payload
      }
    case EDIT_BUNDLE:
      return {
        ...state,  [action.payload.id]: action.payload
      }
    case FETCH_ASSOC_BUNDLES: 
    return {
      ...state, ['associated_bundles']: action.payload
    }
    default:
      return state;
  }
};
