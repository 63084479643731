import _ from 'lodash';
import {
  FETCH_ARTWORKS, FETCH_ARTWORK, EDIT_ARTWORK
} from '../actions/types'


export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_ARTWORKS:
      if (Array.isArray(action.payload)) {
        return { ..._.mapKeys(action.payload, 'id') };
      } else {
        return action.payload
      }
    case FETCH_ARTWORK:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_ARTWORK:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
};
