import _ from 'lodash';
import { FETCH_CUSTOMER_GROUPS_FOR_USER } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_GROUPS_FOR_USER:
      return { ...state, [action.user_id]: action.payload };
    default:
      return state;
  }
};