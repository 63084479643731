import React, { useState, useEffect } from 'react'
import { forgotAdminPassword } from '../../actions'
import { Container, Row, Col, CardGroup, Card, CardBody, Button } from 'reactstrap'
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import logo from '../../assets/img/brand/market-fuel-login.png'
import MFLogo from '../logo'

const ForgotPasswordForm = props => {
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()

  const handleChange = e => setEmail(e.target.value.toLowerCase())

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(forgotAdminPassword(email))
  }

  return (
    <div className="app flex-row align-items-center login-container">
      <Container>
        <Row className="login-page justify-content-center align-items-center">
          <Col md="8">
            <CardGroup>
              <Card className="text-white py-5 d-md-down-none login-card" style={{ width: '44%' }}>
                <CardBody className="d-flex justify-content-center align-items-center login-card">
                  <div className="admin_login_logo">
                    <MFLogo variant="icon" />
                  </div>
                </CardBody>
              </Card>
              <Card className="p-2 mb-0 card-password-form login-card">
                <CardBody className="card-body-password-form">
                  <h1>Admin Password Reset</h1>
                  <p className="text-muted">Enter your email to reset your password</p>
                  <AvForm onValidSubmit={handleSubmit}>
                    <AvGroup className="mb-2">
                      <AvField
                        onChange={handleChange}
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                        name="email"
                        className="sign-in-input"
                        validate={{
                          required: { value: true, errorMessage: 'Please enter an email' },
                          email: { value: true, errorMessage: 'Please enter a valid email' },
                        }}
                      />
                    </AvGroup>
                    <Row>
                      <Col>
                        <Button type="submit" className="px-4 full-width-button login-button">
                          Submit
                        </Button>
                      </Col>
                    </Row>
                    <Row className="last-row-password-form">
                      <Col className="pt-2 mb-0 mt-15">
                        <Link to="/login">Return to Sign In</Link>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ForgotPasswordForm
