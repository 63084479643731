import * as Types from '../actions/types'

const saveAndActionOptions = [
  { value: 'save_edit', label: 'Save and Edit', onPressKey: 'onSaveAndEdit' },
  { value: 'save_close', label: 'Save and Close', onPressKey: 'onSaveAndClose' },
]

const initialState = {
  logInAsCustomer: {},
  saveAndAction: {
    options: saveAndActionOptions,
    selectedOption: saveAndActionOptions[0],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_LOGIN_AS_CUSTOMER_OPTIONS: {
      return {
        ...state,
        logInAsCustomer: {
          ...state.logInAsCustomer,
          [action.payload.userId]: {
            ...state.logInAsCustomer[action.payload.userId],
            options: action.payload.options,
          },
        },
      }
    }
    case Types.SAVE_AND_ACTION_CHANGE:
      return {
        ...state,
        saveAndAction: {
          ...state.saveAndAction,
          selectedOption: action.payload,
        },
      }
    default:
      return state
  }
}