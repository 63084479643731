// ** new component to replace all product details show components **
import {useState, useEffect} from 'react'
import { DateParser } from '../general/DateParser.js';
import {Card, CardBody, Table} from 'reactstrap';
import {connect} from 'react-redux'
import TierPricingComponent from './TierPricingComponent.js';
import { fetchAggregatedProduct,
  fetchProductShqShippingGroups,
  fetchProductShqShippingOrigins,
  fetchProductShqShippingBoxes,
  fetchProductShqPackingRule,
 } from '../../actions/index';

const ProductDetailsNew = props => {
  const [productDetails, setProductDetails] = useState({})

  useEffect(() => {
    if (props.match && props.match.params && props.match.params.product_id) {
      let product_id = props.match.params.product_id
      if (product_id) {
        props.fetchProductShqShippingGroups(product_id, 'generic', true);
        props.fetchProductShqShippingOrigins(product_id, 'generic', true);
        props.fetchProductShqShippingBoxes(product_id, 'generic', true);
        props.fetchProductShqPackingRule(product_id, 'generic', true);
        props.fetchAggregatedProduct(product_id, "generic")
        .then((response) => {
          // If rendered from the React Router, we can ascertain detail level
          // from the params
          if (response) {
            let definedDetailLevel
            if (props.match.params.parent) {
              definedDetailLevel = props.match.params.parent
            } else {
              definedDetailLevel = detailLevel === 'default' ? 'generic' : detailLevel
            }
            const productDetailData = response.data
            setProductDetails(productDetailData)
          }
        })
      } else {
        // If rendered from the React Router, we can ascertain detail level
        // from the params
        let definedDetailLevel
        if (props.match.params.parent) {
          definedDetailLevel = props.match.params.parent
        } else {
          definedDetailLevel = detailLevel === 'default' ? 'generic' : detailLevel
        }

        const productDetailData = props[`aggregated_${definedDetailLevel}_product`]
        setProductDetails(productDetailData)
      }
    }
  }, [props.match])

  const {
    product_shipping_groups,
    product_shipping_origins,
    product_shipping_boxes,
    product_packing_rule,
    detailLevel
  } = props;

  const attributeDefinition = (label, value) => {
    return (<>
      <dt className="col-sm-3">{label}</dt>
      <dd className="col-sm-9">{value}</dd>
    </>)
  }

  // get product details at the correct detail level
  const shipping_groups  =  product_shipping_groups[detailLevel]
  const shipping_origins =  product_shipping_origins[detailLevel]
  const shipping_boxes   =  product_shipping_boxes[detailLevel]
  const packing_rules    =  product_packing_rule[detailLevel]

  return (
    <div className="bd-example">
      <dl className="row">

        <dt className="col-sm-3">Product name </dt>
        <dd className="col-sm-9">
          { productDetails && !productDetails.title && productDetails.name }
          { productDetails && productDetails.title && productDetails.title }
        </dd>

        {attributeDefinition('Client name', productDetails.client_name)}
        {attributeDefinition('Vendor name', productDetails.vendor_name)}
        {attributeDefinition('SKU', productDetails.sku)}
        {attributeDefinition('Vendor SKU', productDetails.vendor_sku )}
        {attributeDefinition('Item type', productDetails.item_type )}
        {attributeDefinition('Allow artwork', (productDetails.allow_artwork ? 'True' : 'False') )}
        {attributeDefinition('Allow quantity below 0', (productDetails.allow_qty_below_0 ? 'True' : 'False') )}
        {attributeDefinition('Created at', DateParser(productDetails.created_at) )}
        {attributeDefinition('Description', productDetails.description )}
        {attributeDefinition('Product Spec Button 1 Title', productDetails.product_spec_btn_title_1 )}
        {attributeDefinition('Product Spec Button 1 Image URL', productDetails.product_spec_url_1 )}
        {attributeDefinition('Product Spec Button 2 Title', productDetails.product_spec_btn_title_2 )}
        {attributeDefinition('Product Spec Button 2 Image URL', productDetails.product_spec_url_2 )}
        {attributeDefinition('Product Spec Button 3 Title', productDetails.product_spec_btn_title_3 )}
        {attributeDefinition('Product Spec Button 3 Image URL', productDetails.product_spec_url_3 )}
        {attributeDefinition('Enable qty increments', (productDetails.enable_qty_increments ? 'True' : 'False') )}
        {attributeDefinition('Enable sales tax', (productDetails.enable_tax  ? 'True' : 'False') )}
        {attributeDefinition('Minimum qty allowed in shopping cart', productDetails.minimum_qty_allowed_in_shopping_cart )}
        {attributeDefinition('Hide from shop', (productDetails.hide_from_shop  ? 'True' : 'False') )}
        {attributeDefinition('Price', productDetails.price )}
        {attributeDefinition('Sale Price', productDetails.sale_price )}
        {attributeDefinition('Tier price',
          <TierPricingComponent
            // portal_id={props.portal_id}
            detailLevel='default'
            displayOnly={true}
            tier_prices={productDetails.tier_prices ? productDetails.tier_prices : []}
            isClientProduct={!!productDetails.client_id}
          />
        )}
        {attributeDefinition('Sale price from date and time', productDetails.sale_price_from_date_and_time )}
        {attributeDefinition('Sale price to date and time', productDetails.sale_price_to_date_and_time )}
        {/* {attributeDefinition('Valid product', ( productDetails.valid_product ? 'True' : 'False') )} */}
        <Card variant="outlined">
          <CardBody >
            <dl className="row">
              {attributeDefinition('Shipping Groups',
                Array.isArray(shipping_groups) && shipping_groups.map((group, i) => {
                  return <span key={'default_shipping_group' + i} className="shipping-selected-names" key={group.id}>{group.name} </span>
                })
              )}
              {attributeDefinition('Origins',
                Array.isArray(shipping_origins) && shipping_origins.map((group, i) => {
                  return <span key={'generic_shipping_origin' + i} className="shipping-selected-names" key={group.id}>{group.name} </span>
                })
              )}
              {attributeDefinition('Packing Rules',
                Array.isArray(packing_rules) && packing_rules.map((group, i) => {
                  return <span key={'generic_packing_rule' + i} className="shipping-selected-names" key={group.id}>{group.name} </span>
                })
              )}
              {attributeDefinition('Shipping Boxes',
                Array.isArray(shipping_boxes) && shipping_boxes.map((group, i) => {
                  return <span key={'generic_shipping_box' + i} className="shipping-selected-names" key={group.id}>{group.name} </span>
                })
              )}
            </dl>
            {attributeDefinition('Weight (lb)', productDetails && productDetails.dimension_weight && productDetails.dimension_weight.toFixed(4) )}
            {attributeDefinition('Width (inches)', productDetails && productDetails.dimension_width && productDetails.dimension_width.toFixed(4) )}
            {attributeDefinition('Length (inches)', productDetails && productDetails.dimension_length && productDetails.dimension_length.toFixed(4) )}
            {attributeDefinition('Height (inches)', productDetails && productDetails.dimension_height && productDetails.dimension_height.toFixed(4) )}
            {attributeDefinition('Flat Shipping Fee (in dollars)', productDetails && productDetails.flat_shipping_fee &&  productDetails.flat_shipping_fee.toFixed(4) )}
            {attributeDefinition('Is Malleable', productDetails && ( productDetails.is_malleable  ? 'True' : 'False')  )}
            {attributeDefinition('Ship Separately', productDetails && ( productDetails.ship_separately  ? 'True' : 'False')  )}
          </CardBody>
        </Card>
      </dl>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    aggregated_generic_product:  state.aggregated_generic_product,
    aggregated_client_product:   state.aggregated_client_product,
    aggregated_portal_product:   state.aggregated_portal_product,
    aggregated_location_product: state.aggregated_location_product,
    product_shipping_groups:     state.product_shipping_groups,
    product_shipping_origins:    state.product_shipping_origins,
    product_shipping_boxes:      state.product_shipping_boxes,
    product_packing_rule:        state.product_packing_rule,
  }
}

export default connect(mapStateToProps, {
  fetchAggregatedProduct,
  fetchProductShqShippingGroups,
  fetchProductShqShippingOrigins,
  fetchProductShqShippingBoxes,
  fetchProductShqPackingRule
})(ProductDetailsNew);

// export for testing
export { ProductDetailsNew };
