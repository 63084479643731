import _ from 'lodash';
import { FETCH_CARRIER_RATES_MULTI_BOX } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CARRIER_RATES_MULTI_BOX:
      return action.payload;
    default:
      return state;
  }
};
