// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sweet-loading {
  position: fixed;
  top: 40%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 99;
}
`, "",{"version":3,"sources":["webpack://./src/components/spinner/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,UAAU;EACV,+BAA+B;EAC/B,WAAW;AACb","sourcesContent":[".sweet-loading {\n  position: fixed;\n  top: 40%;\n  right: 50%;\n  transform: translate(50%, -50%);\n  z-index: 99;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
