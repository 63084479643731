import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import SsoFeaturebaseRedirect from '../components/external_sso/SsoFeaturebaseRedirect.js'
import LoadingSpinner from '../components/general/LoadingSpinner'
import SSoSignInPage from '../components/sessions/SSoSignInPage'

import SessionsLayout from '../containers/SessionsLayout/SessionsLayout'
import Error from '../views/pages/misc/Error'
import ResolveRoutes from './ResolveRoutes.js'

// const NotAuthorized = lazy(() => import('../views/pages/misc/NotAuthorized'))

const MFRouter = props => {
  const currentAgencyPresent = props.currentAgency

  return (
    <BrowserRouter basename="/">
      <Switch>
        {/* Sessions layout should be publicly accessible */}
        <Route
          exact
          path={['/login', '/forgot_password', '/reset_password/:token', '/auth0_callback']}
          component={SessionsLayout}
        />
        <Route path="/sso_featurebase_redirect" component={SsoFeaturebaseRedirect} />

        <Route path="/sso-log-in/:sso_id/:sso_token" component={SSoSignInPage} />
        {currentAgencyPresent ? <ResolveRoutes {...props} /> : <LoadingSpinner />}
      </Switch>
    </BrowserRouter>
  )
}

const mapStateToProps = state => {
  return {
    currentAdmin: state.admins.current_admin,
    currentAgency: state.agencies.currentAgency
  }
}

export default connect(mapStateToProps)(MFRouter)
