export default function dollar_price(price_in_cents, dec = 2){
  if (typeof price_in_cents == 'string') {
    price_in_cents = parseInt(price_in_cents, 10)
  }
  if ((typeof price_in_cents !== 'number') || (typeof dec !== 'number'))
    return false;
  var num_sign = price_in_cents >= 0 ? 1 : -1;

  let result = (Math.round(((price_in_cents/100)*Math.pow(10,dec))+(num_sign*0.0001))/Math.pow(10,dec)).toFixed(dec)

  return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}