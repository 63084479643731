import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { saveUrlToLocalStorage } from '../../utility/Utils'

const SsoFeaturebaseRedirect = ({ featurebaseAuthenticationToken, isAdminLoading }) => {
  const history = useHistory()

  useEffect(() => {
    //Check if current admin is available
    if (isAdminLoading) {
      return
    }

    if (featurebaseAuthenticationToken) {
      // Construct the URL with the JWT token
      const redirectUrl = `https://marketfuel.featurebase.app/api/v1/auth/access/jwt?jwt=${featurebaseAuthenticationToken}&return_to=https://feedback.marketfuel.com/changelog`
      // Redirect to the URL
      window.location.href = redirectUrl
    } else {
      saveUrlToLocalStorage('/featurebase_sso_redirect')
      history.push('/login')
    }
  }, [featurebaseAuthenticationToken])

  return null
}

// Map the required state to props
const mapStateToProps = state => ({
  featurebaseAuthenticationToken: state.admins.current_admin.featurebase_authentication_token,
  isAdminLoading: state.loading.admin
})

export default connect(mapStateToProps)(SsoFeaturebaseRedirect)
