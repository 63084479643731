export default (
  state = {
    error: '', // 'TEST'
    // resetFilterButtons: false,
  },
  action
) => {
  switch (action.type) {
    case 'SET_MF_ERROR':
      return {
        ...state,
        error: action.payload.error,
      }
    case 'SET_DATA_GRID':
      return { ...state, [action.payload.id]: action.payload }
    case 'CLEAR_MF_ERRORS':
      return { ...state, error: '' }
    default:
      return state
  }
}
