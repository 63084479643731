import _ from 'lodash';
import { FETCH_CARD_PAYMENTS } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CARD_PAYMENTS:
      return { ...action.payload };
    default:
      return state;
  }
};
