import _ from 'lodash';
import {  CREATE_TIER_PRICE, UPDATE_TIER_PRICE, DELETE_TIER_PRICE } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case CREATE_TIER_PRICE:
      return { ...state, [action.payload.id]: action.payload };
    case UPDATE_TIER_PRICE:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_TIER_PRICE:
      return action.payload;
    default:
      return state;
  }
};
