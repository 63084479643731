import _ from 'lodash';
import { UPLOAD_RESULTS } from '../actions/types'

export default (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_RESULTS:
      return   action.payload
    case 'CLEAR_UPLOADED_RESULTS':
      return {}
    default:
      return state;
  }
};
