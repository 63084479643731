import _ from 'lodash';
import { FETCH_WHITELISTED_IPS, CREATE_WHITELISTED_IP, EDIT_WHITELISTED_IP } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_WHITELISTED_IPS:
      return action.payload;
    case CREATE_WHITELISTED_IP:
      return action.payload;
    case EDIT_WHITELISTED_IP:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
};