import { NODE_ENV, REACT_APP_BACKEND_API_URL } from '../Constants'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
  const loggedIn = sessionStorage.getItem('agencyAuthToken') ? true : false
  return loggedIn
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed', // for input hover border-color
  },
})

export const titleizeField = fieldname => {
  if (!fieldname) return ''
  return fieldname
    .split('_')
    .map(word => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join(' ')
}

export const titleToSnakeCase = inputString => inputString.replace(/ /, '_').toLowerCase()

export const formatPhoneNumber = phoneString => {
  if (!phoneString) {
    return ''
  }

  // If the phone starts with a "+", assume it's an international number and return as is
  if (phoneString.charAt(0) === '+') {
    return phoneString
  }

  // Strip all non-digit chars
  let value = phoneString.replaceAll(/\D/g, '')

  const first = value.substring(0, 3)
  const middle = value.substring(3, 6)
  const last = value.substring(6, 10)

  if (value.length > 6) {
    value = `(${first}) ${middle} - ${last}`
  } else if (value.length > 3) {
    value = `(${first}) ${middle}`
  } else if (value.length > 0) {
    value = `(${first}`
  }

  return value
}


export const getCountryByPhoneString = (countries, phoneString) => {
  if (!phoneString) {
    return 'US'
  }

  let hasCountryCode, value

  if (phoneString.charAt(0) === '+') {
    hasCountryCode = true

    const twoDigitPhoneCode = phoneString.substring(1, 3)
    const oneDigitPhoneCode = phoneString.substring(1, 2)

    if (oneDigitPhoneCode === '1') {
      return 'US'
    }

    const thisCountry = countries.find(country => [oneDigitPhoneCode, twoDigitPhoneCode].includes(country.phoneCode))

    if (thisCountry) {
      return thisCountry.country_code
    } else {
      return ''
    }
  } else {
    hasCountryCode = false
    return 'US'
  }
}

export const getCountryByPhoneCode = (countries, phoneCode) => {}

export const round = (num, precision) => {
  var base = 10 ** precision
  return (Math.round(num * base) / base).toFixed(precision)
}

export const mapQtyToColor = (qtyOnHand, qtyNeeded) => {
  if (qtyOnHand >= qtyNeeded) {
    return null
  } else if (qtyOnHand > 0 && qtyOnHand < qtyNeeded) {
    return '#E67327'
  } else {
    return '#c93e1e'
  }
}

// export const currentEnvironment = () => {
//   const environment = NODE_ENV
//   const backendUrl = REACT_APP_BACKEND_API_URL


//   if (!backendUrl) {
//     alert('Missing environment variable: REACT_APP_BACKEND_API_URL')
//     return
//   }

//   if (environment !== 'development' && backendUrl.indexOf('staging') !== -1) {
//     return 'staging'
//   } else if (environment && backendUrl.indexOf('demo') !== -1) {
//     return 'demo'
//   } else if (environment && environment !== 'production') {
//     return 'development'
//   } else {
//     return environment
//   }
// }

// used to trace updates to props for a functional component
export const useTraceUpdate = (props, source = '') => {
  const prev = useRef(props)
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v]
      }
      return ps
    }, {})
    // if (Object.keys(changedProps).length > 0) {
    //
    // }
    prev.current = props
  })
}

export const truncateString = (str, num = 100) => {
  if (str.length <= num) {
    return str
  } else {
    return str.slice(0, num) + '...'
  }
}

export const arrayToReactSelect = array => {
  return array.map(val => ({ value: val, label: val }))
}

// convert CSV file with headers to array of objects
export const csvToJSON = file => {
  const reader = new FileReader()

  reader.onload = event => {
    // Parse the CSV data into an array of objects
    const fileData = event.target.result
    const rows = fileData.split(/\r?\n/)
    const headers = rows[0].split(',')
    // Remove the first row (headers)
    const data = rows.slice(1).map(row => {
      const values = row.split(',')
      return headers.reduce((obj, header, index) => {
        obj[header.trim()] = values[index].trim()
        return obj
      }, {})
    })

    return data
  }

  return reader.readAsText(file)
}

export const isProductNeedsConfiguration = product => {
  if (!product) return false
  return product.is_configurable && !product.is_configured
}

export const selectPortalFields = (portals) => {
  const newPortals = portals.map(portal => {
    return {
      id: portal.id,
      name: portal.name,
    }
  })
  return [{ id: -1, name: 'All Portals' }].concat(newPortals)
}

/**
 * formatSentence
 * @param {Array<String>} words
 * @returns stringified sentence with commas and 'and' before the last word
 */
export const formatSentence = words => {
  if (words.length === 0) return '';
  if (words.length === 1) return words[0];
  if (words.length === 2) return words.join(' and ');

  const allButLast = words.slice(0, -1).join(', ');
  const lastWord = words[words.length - 1];

  return `${allButLast}, and ${lastWord}`;
}

export const saveUrlToLocalStorage = () => {
  localStorage.setItem('redirectUrl', window.location.pathname)
}

/**
 * Utility function for localStorage actions.
 *
 * @param {'get' | 'set' | 'remove' | 'clear'} action - The action to perform on localStorage.
 * @returns {(function(string, any=): any) | undefined} - The corresponding function to handle the specified action, or undefined if the action is not recognized.
 */
export const localStorageUtility = (action = 'get') => {
  const returnedActions = {
    /**
     * Get an item from localStorage.
     * @param {string} key - The key of the item to retrieve.
     * @returns {any} - The parsed value of the item in localStorage.
     */
    get: (key) => JSON.parse(window.localStorage.getItem(key)),

    /**
     * Set an item in localStorage.
     * @param {string} key - The key of the item to set.
     * @param {any} value - The value of the item to set.
     */
    set: (key, value) => {
      window.localStorage.setItem(key, JSON.stringify(value))
    },

    /**
     * Remove an item from localStorage.
     * @param {string} key - The key of the item to remove.
     */
    remove: (key) => {
      window.localStorage.removeItem(key)
    },

    /**
     * Clear all items in localStorage.
     */
    clear: () => {
      window.localStorage.clear()
    }
  }

  return returnedActions[action]
}
