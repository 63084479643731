import _ from 'lodash';
import { FETCH_SETTINGS, FETCH_SETTING, EDIT_SETTING, DELETE_SETTING } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_SETTINGS:
      return { ...state, ..._.mapKeys(action.payload, 'id') };
    case FETCH_SETTING:
      return { ...state, [action.payload.id]: action.payload };
    // case CREATE_SETTING:
    //   return { ...state, [action.payload.id]: action.payload };
    case EDIT_SETTING:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_SETTING:
      return _.omit(state, action.payload );
    default:
      return state;
  }
};
