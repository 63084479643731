import _ from 'lodash';
import { FETCH_DP_DATA, UPDATE_DIGITAL_PROOFING_PRODUCT, FETCH_BATCH_DP_DATA } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_DP_DATA:
      return { ...state, [action.id]: action.payload };
    case FETCH_BATCH_DP_DATA:
      return action.payload
    case UPDATE_DIGITAL_PROOFING_PRODUCT:
      return { ...state, [action.id]: action.payload };
    default:
      return state;
  }
};
