export default (state = [], action) => {
  let newState
  switch (action.type) {
    case 'FETCH_SHIPPING_DIMENSION_PRESETS':
      return action.payload
    case 'CREATE_SHIPPING_DIMENSION_PRESETS':
      return [action.payload, ...state]
    case 'EDIT_SHIPPING_DIMENSION_PRESETS':
      newState = state.map(obj => (action.payload.id == obj.id ? action.payload : obj))
      return newState
    case 'DELETE_SHIPPING_DIMENSION_PRESETS':
      newState = state.filter(obj => obj.id !== action.payload.id)
      return newState
    default:
      return state
  }
}
