import _ from 'lodash';
import {
  CREATE_CONFIGURABLE_OPTION_DETAILS,
  CREATE_CONFIGURABLE_OPTION,
  EDIT_CONFIGURABLE_OPTION,
  DELETE_CONFIGURABLE_OPTION,
  FETCH_CONFIGURABLE_OPTION,
  FETCH_CONFIGURABLE_OPTIONS
} from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CONFIGURABLE_OPTIONS:
      return _.mapKeys(action.payload, 'id');
    case FETCH_CONFIGURABLE_OPTION:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_CONFIGURABLE_OPTION:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_CONFIGURABLE_OPTION:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_CONFIGURABLE_OPTION:
      return _.omit(state, action.payload );
    case CREATE_CONFIGURABLE_OPTION_DETAILS:
      return _.mapKeys(action.payload, 'id');
    default:
      return state;
  }
};
