import {useState, useEffect} from 'react'
import { Col, Row, Button, FormGroup, Label, Input, CardBody, Table } from 'reactstrap';
import MultiSelect from 'react-widgets/lib/Multiselect'
import 'react-widgets/dist/css/react-widgets.css'
import {connect} from "react-redux";
import {
  fetchCustomerGroups,
  deleteTierPrice
} from "../../actions";
import Spinner from "../spinner";
import { Trash2, Plus } from 'react-feather'
import _ from 'lodash'
import MfSelect from '../general/MfSelect';

const filterByDetailLevel = (tierPrices, detailLevel, isClientProduct) => {
  return tierPrices.filter(tierPrice => {
    return tierPrice.detail_level === detailLevel
  })
}

const TierPricingComponent = ({
  portal_id,
  detailLevel,
  tier_prices_data,
  fetchCustomerGroups,
  displayOnly,
  customer_groups,
  isClientProduct,
  tier_prices,
  setTierPricesForm
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [tierPrices, setTierPrices] = useState([])

  useEffect(() => {
    if (setTierPricesForm) {
      setTierPricesForm(tierPrices)
    }
  }, [tierPrices])
  
  useEffect(() => {
    fetchCustomerGroups(portal_id).then(() => {
      let tierPricesArray = []
        if (tier_prices && tier_prices.length > 0 ) {
          tierPricesArray = tier_prices.map( tierPrice => {
            let customerGroupIds = tierPrice.customer_groups.map( customerGroup => customerGroup.id )
            return {
              ...tierPrice,
              customer_groups:     customerGroupIds,
              product_qty:        tierPrice.product_qty,
              unit_price_cents:   tierPrice.unit_price_cents,
              price_per_qty_text: tierPrice.price_per_qty_text,
            }
          })
        }
      setIsLoading(false)

      // optionally filter for tier prices of a certain detail level
      if (detailLevel) {
        tierPricesArray = tierPricesArray.filter(tierPrice => tierPrice.detail_level === detailLevel)
      }
      
      setTierPrices(tierPricesArray)
      if (displayOnly === true) return;
    })
  }, [])

  const checkChange = (e, index, fieldName = null) => {
    let tierPricesCopy = _.cloneDeep(tierPrices)
    const name = fieldName ? fieldName : e.target.name
    if (name === "customer_groups") {
      tierPricesCopy[index].customer_groups = e.map(x => x.id)
    } else {
      tierPricesCopy[index][name] = e.target.value;
    }
    setTierPrices( tierPricesCopy )
    tier_prices_data(tierPrices)
  };

  const newTierPrice = () => {
    setTierPrices([ ...tierPrices, {} ])
  }

  const deleteTierPrice = (index) => {
    // const tierPricesSpliced = _.cloneDeep(tierPrices).splice(index, 1);
    const tierPricesSpliced = _.cloneDeep(tierPrices)
    _.pullAt(tierPricesSpliced, index) 
    setTierPrices( tierPricesSpliced );
  }
  
  if (displayOnly) {
    return <CardBody>
      <Table style={{padding:"0px"}} aria-label="simple table">
        <thead>
          <tr>
            <td style={{fontWeight: "bold", fontSize: "12px", padding:"5px"}} align="left">ID</td>
            <td style={{fontWeight: "bold", fontSize: "12px", padding:"5px"}} align="left">Product Qty</td>
            <td style={{fontWeight: "bold", fontSize: "12px", padding:"5px"}} align="left">Unit Price Cents</td>
            <td style={{fontWeight: "bold", fontSize: "12px", padding:"5px"}} align="left">Display text</td>
          </tr>
        </thead>
        <tbody>
          {filterByDetailLevel(tier_prices, detailLevel, isClientProduct).map((tier_price, i) => (
            <tr key={'tierPrice' + i}>
              <td style={{fontSize: "12px", padding:"5px"}} align="left">{tier_price.id}</td>
              <td style={{fontSize: "12px", padding:"5px"}} align="left">{tier_price.product_qty}</td>
              <td style={{fontSize: "12px", padding:"5px"}} align="left">{tier_price.unit_price_cents}</td>
              <td style={{fontSize: "12px", padding:"5px"}} align="left">{tier_price.price_per_qty_text}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </CardBody>
  } else return (
    <div className="tier-price-box">
      {isLoading ? <Spinner /> : null}

      <div style={{paddingLeft: "0px"}}>
        <label>Tier Prices:</label>
      </div>

      <div style={{display: "flex"}}>
        {portal_id &&
          <Col md={3} style={{paddingLeft: "0px"}}>
            <Label>Customer Group(s):</Label>
          </Col>
        }
        <Col md={1} style={{paddingLeft: "0px"}}>
          <Label>Quantity:</Label>
        </Col>
        <Col md={2} style={{paddingLeft: "0px"}}>
          <Label>Unit Price Cents:</Label>
        </Col>
        <Col md={5} style={{paddingLeft: "0px"}}>
          <Label>Portal text display:</Label>
        </Col>
      </div>

      {tierPrices.map((tierPrice, index) => {
        return(
          <Row form key={'tier_price_form_row_' + index}>
            {portal_id &&
              <Col md={3}>
                <FormGroup>
                  <MfSelect 
                    name="customer_groups"
                    options={customer_groups}
                    value={customer_groups.filter(group => tierPrice.customer_groups?.includes(group.id))}
                    placeholder="Select Customer Group(s)"
                    onChange={(e) => checkChange(e, index, "customer_groups")}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isMulti={true}
                  />
                </FormGroup>
              </Col>
            }
            <Col md={1}>
              <FormGroup>
                <Input
                  type="number"
                  min={0}
                  name="product_qty"
                  id="product_qty"
                  value={tierPrice.product_qty}
                  onChange={e => checkChange(e, index) }/>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Input
                  type="number"
                  min={0}
                  name="unit_price_cents"
                  id="unit_price_cents"
                  value={tierPrice.unit_price_cents}
                  onChange={e =>  checkChange(e, index) }/>
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Input
                  type="text"
                  name="price_per_qty_text"
                  id="price_per_qty_text"
                  value={tierPrice.price_per_qty_text}
                  onChange={e => checkChange(e, index) }/>
              </FormGroup>
            </Col>
            <Col md={1}>
              <Button
                size="sm"
                color="danger"
                className="btn-left-space"
                onClick={() => deleteTierPrice(index)}><Trash2 />
              </Button>
            </Col>
          </Row>
        )})}
      <Button color="primary" onClick={newTierPrice}><Plus /></Button>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    customer_groups: state.customer_groups
  };
};

export default connect(
  mapStateToProps,
  {
    fetchCustomerGroups,
    deleteTierPrice
  })(TierPricingComponent);
