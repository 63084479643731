import { connect } from 'react-redux'
import { setSessionToken } from '../../actions/sessions'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { createSession } from '../../actions/sessions'
import { toastr } from 'react-redux-toastr'
import LoadingSpinner from '../general/LoadingSpinner'

// Dummy component to wait for app to fetch admin via SSO
const SSoSignInPage = ({ setSessionToken, createSession, session }) => {
  const { sso_id, sso_token } = useParams()
  const history = useHistory()

  // wait for data to load, then create session using SSO ID/token from params
  useEffect(() => {
    if (sso_id && sso_token && !session) {
      // get store_domain from url params if present
      const store_domain = new URLSearchParams(window.location.search).get('store_domain')
      const logoutDestination = store_domain || '/login'
      localStorage.setItem('store_domain', logoutDestination)
      setSessionToken(sso_token)
        .catch(err => {
          console.error('Error creating session with SSO ID/token')
          toastr.error('Log in error.', 'Please try again')
        })
        .finally(() => {
          history.push("/")
        })
    }
  }, [sso_id, sso_token])

  return (
    <div>
      <LoadingSpinner />
    </div>
    // <>
    //   <h4>Please wait while we redirect you.</h4>
    //   <p>
    //     You will be redirected shortly. If you are having issues signing in, please contact an administrator for
    //     assistance.
    //   </p>
    // </>
  )
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, { setSessionToken, createSession })(SSoSignInPage)
export { SSoSignInPage }
