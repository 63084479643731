import _ from 'lodash'

const initialState = {
  message: '',
  // status: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOGIN_STATUS':
      return action.payload
    case 'CLEAR_LOGIN_STATUS':
      return initialState
    default:
      return state
  }
}
