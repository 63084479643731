import _ from 'lodash';
import { FETCH_PORTAL_ACCOUNTS } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PORTAL_ACCOUNTS:
      return  action.payload ;
    default:
      return state;
  }
};
