import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getOrderErrorsCount } from '../../../../actions'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

const OrderErrorsCount = (props) => {
  const [showOrderErrorsMsg, setShowOrderErrorsMsg] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (props.current_admin.is_super_admin) {
      props.getOrderErrorsCount()
    }
  }, [location, props.current_admin])

  useEffect(() => {
    if (props.order_errors_count > 0 && props.current_admin.is_super_admin) {
      setShowOrderErrorsMsg(true)
    } else {
      setShowOrderErrorsMsg(false)
    }
  }, [props.order_errors_count, props.current_admin])

  return (
    <>
      {showOrderErrorsMsg &&
        <Link to="/orders/errors" className="nav-bar-warning"> 
          {props.order_errors_count} orders are stuck in processing!
        </Link>
      }
    </>
  )
}

const mapStateToProps = state => {
  return {
    current_admin: state.admins.current_admin,
    order_errors_count: state.order_errors_count
  }
}

export default connect(mapStateToProps, { getOrderErrorsCount })(OrderErrorsCount);