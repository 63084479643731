import _ from 'lodash'
import {
  FETCH_GENERIC_TEMPLATE,
  EDIT_GENERIC_TEMPLATE,
  FETCH_HOME_TEMPLATE,
  FETCH_ABOUT_TEMPLATE,
} from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_GENERIC_TEMPLATE:
      return { ...state, [action.payload.id]: action.payload }
    case EDIT_GENERIC_TEMPLATE:
      return { ...state, [action.payload.id]: action.payload }
    case FETCH_HOME_TEMPLATE:
      return { ...state, [action.payload.id]: action.payload }
    case FETCH_ABOUT_TEMPLATE:
      return { ...state, [action.payload.id]: action.payload }
    default:
      return state
  }
}
