import _ from 'lodash';
import { FETCH_AGGREGATED_GENERIC_PRODUCT, EDIT_GENERIC_PRODUCT } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_AGGREGATED_GENERIC_PRODUCT:
      return action.payload;
    case EDIT_GENERIC_PRODUCT:

      return { ...state, [action.payload.id]: action.payload };
      // // return { ...state, [action.payload.id]: action.payload };
      // return Object.assign({}, state, {[action.payload.id]: action.payload} );
    default:
      return state;
  }
};
