import _ from 'lodash'
import {
  FETCH_PORTAL_PAGES,
  CREATE_PAGE,
  FETCH_PAGE,
  EDIT_PAGE,
  DELETE_PAGE,
  BULK_UPDATE_PAGES,
  CREATE_PAGE_CONTENT,
  UPDATE_CONTENT_BLOCK,
  FETCH_LOCATIONS,
  SET_PAGES_LOADING
} from '../actions/types'

const initialState = {
  loading: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PORTAL_PAGES:
      return action.payload
    case FETCH_PAGE:
      return { ...state, [action.payload.id]: action.payload }
    case CREATE_PAGE:
      return { ...state, [action.payload.id]: action.payload }
    case EDIT_PAGE:
      return { ...state, [action.payload.id]: action.payload }
    case DELETE_PAGE:
      return _.omit(state, action.payload)
    case BULK_UPDATE_PAGES:
      return action.payload
    case CREATE_PAGE_CONTENT:
      return state
    case UPDATE_CONTENT_BLOCK:
      const incomingBlock = action.payload
      const pageId = incomingBlock.page_id
      const existingBlocks = state[pageId].page_content_blocks
      return {
        ...state,
        [pageId]: {
          ...state[pageId],
          page_content_blocks: [
            ...existingBlocks.filter(
              block => block.id !== incomingBlock.id
            ),
            incomingBlock,
          ],
        }
      }
    case FETCH_LOCATIONS: {
      return { ...state, loading: false }
    }
    case SET_PAGES_LOADING: {
      return { ...state, loading: action.payload }
    }
    default:
      return state
  }
}
