import _ from 'lodash';
import { FETCH_CARRIER_RATES } from '../actions/types'
import dollar_price from '../components/general/DollarPrice';

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CARRIER_RATES:
      const rates = action.payload
      const carrierValue = action.carrierValue
      const formattedRates = rates.map( carrier => {
        const packageType = carrier.packageType
        return {
          ...carrier,
          packageType: (carrierValue === "stamps_com" && packageType) ? packageType.split('_').map(words => words.charAt(0).toUpperCase() + words.slice(1) ).join(' ') : "",
        }
      })
      .map(carrier => {
        const { estimatedDeliveryDate, shippingCost, otherAmount, packageType, serviceType } = carrier
        const deliveryDate = estimatedDeliveryDate ? " (ETA " + new Date(estimatedDeliveryDate).toLocaleDateString('en-us', {month:"short", day: "numeric"}) : ' (ETA N/A'
        const estimateTotal = dollar_price( parseFloat(shippingCost + otherAmount) * 100 )
        return {
          ...carrier,
          serviceType: serviceType + " " + packageType + deliveryDate + ") - $" + estimateTotal
        }
      })
      
      return formattedRates.sort((a, b) => a.shippingCost - b.shippingCost);
    case 'CLEAR_CARRIER_RATES':
      return []
    default:
      return state;
  }
};
