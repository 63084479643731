import _ from 'lodash';
import { FETCH_COOP_BALANCES_BY_TAG } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_COOP_BALANCES_BY_TAG:
      // return { ...state, ..._.mapKeys(action.payload, 'id') };
      return { ...state, [action.payload.id]: action.payload}
    default:
      return state;
  }
};
