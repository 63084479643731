import React, { useCallback, useMemo } from 'react'

// ** Vertical Menu Components
import VerticalNavMenuLink from './VerticalNavMenuLink'
import VerticalNavMenuGroup from './VerticalNavMenuGroup'
import VerticalNavMenuSectionHeader from './VerticalNavMenuSectionHeader'

// ** Utils
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '../../../../../@core/layouts/utils'


// Components Object
const Components = {
  VerticalNavMenuSectionHeader,
  VerticalNavMenuGroup,
  VerticalNavMenuLink,
};

const VerticalMenuNavItems = (props) => {
  // Resolve the component type for each item
  const resolveComponent = (item) => Components[resolveNavItemComponent(item)];

  return (
    <React.Fragment>
      {props.items
        ? props.items.map((item, index) => {
            const TagName = resolveComponent(item);
            return (
              <TagName
                navigation={props.items}
                item={item}
                index={index}
                key={index}
                {...props}
              />
            );
          })
        : null}
    </React.Fragment>
  );
};

export default VerticalMenuNavItems;

// class VerticalMenuNavItems extends React.Component {
//   // ** Render Nav Menu Items
//   RenderNavItems = () => {
//     return (
//       <React.Fragment>
//         {this.props.items
//           ? this.props.items.map((item, index) => {

//             // ** Components Object
//               const Components = () => {
//                 return {
//                   VerticalNavMenuSectionHeader,
//                   VerticalNavMenuGroup,
//                   VerticalNavMenuLink,
//                 }
//               }
//               const TagName = Components()[resolveNavItemComponent(item)]
//               if (item.children) {
//                 return <TagName navigation={this.props.items} item={item} index={index} key={index} {...this.props} />
//               }
//               return (
//                 <TagName
//                   navigation={this.props.items}
//                   key={index}
//                   index={index}
//                   item={item}
//                   {...this.props}
//                 />
//               )
//             })
//           : null}

//         {/* <li className="d-flex align-items-center">
//           <Icon.ThumbsUp />
//           <span className="menu-item text-truncate">
//             <div>
//               <a>Test</a>
//             </div>
//           </span>
//         </li> */}

//         {/* <VerticalNavMenuLink
//           navigation={this.props.items}
//           key={'feedback'}
//           index={'feedback'}
//           {...this.props}
//           item={{
//             newTab: true,
//             name: 'feedback',
//             title: 'Feedback',
//             id: 'feedback',
//             navLink: 'https://marketfuel.nolt.io/',
//             externalLink: true,
//             icon: <Icon.ThumbsUp />,
//           }}
//         >
//         </VerticalNavMenuLink> */}
//       </React.Fragment>
//     )
//   }

//   render() {
//     return this.RenderNavItems()
//   }
// }

// export default VerticalMenuNavItems
