import _ from 'lodash';
import { FETCH_CLIENT_IMAGES, DELETE_CLIENT_IMAGE, EDIT_CLIENT_IMAGE, CREATE_CLIENT_IMAGE } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CLIENT_IMAGES:
      // return { ...state, ..._.mapKeys(action.payload, 'id') };
      return action.payload;
    case DELETE_CLIENT_IMAGE:
      return _.omit(state, action.payload );
    case EDIT_CLIENT_IMAGE:
      return {..._.mapKeys(state, 'id'), [action.payload.id]: action.payload}
    case CREATE_CLIENT_IMAGE: 
      return {..._.mapKeys(state, 'id'), [action.payload.id]: action.payload}
    default:
      return state;
  }
};
