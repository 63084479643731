import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'

class BreadCrumbs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      crumbs: [
        {
          link: '/test/14',
          name: 'Test Crumb',
        },
      ],
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.route && prevProps.route.path !== this.props.route.path) {
      // nothing
    }
  }

  sortCrumbs() {
    let crumbArray = []

    this.props.crumbs.map(crumb => {
      // this.state.crumbs.map(crumb => {
      if (crumb.root === true) {
        crumbArray = []
        crumbArray.push(crumb)
        return
      }
      //if crumb is new -> forward navigation, add crumb to the array
      if (crumbArray.findIndex(i => i.name === crumb.name) < 0) {
        crumbArray.push(crumb)
      }
      //if crumb is not new -> backward navigation, remove all crumbs after this point
      else {
        let idx = crumbArray.findIndex(i => i.name === crumb.name)
        crumbArray.splice(idx + 1, crumbArray.length)
        return
      }
    })
    return crumbArray
  }

  breadcrumbSwitch(param) {
    switch (param) {
      case 'Clients':
        return { background: '#caffc7' }
      case 'Portals':
        return { background: '#fdffc7' }
      case 'Products':
        return { background: '#f5c687' }
      case 'Admins':
        return { background: '#fbd2d9' }
      default:
        return { background: '#fff' }
    }
  }

  render() {
    if (this.props.crumbs.length > 0) {
      let crumbs = this.sortCrumbs()
      let breadcrumbRoot = crumbs[0].root ? crumbs[0].name : ''
      return (
        <Breadcrumb>
          <BreadcrumbItem key="home">
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          {crumbs.map(crumb => {
            return (
              <BreadcrumbItem key={crumb.name}>
                <a href={crumb.path}>{crumb.name}</a>
              </BreadcrumbItem>
            )
          })}
        </Breadcrumb>
      )
    } else {
      return (
        <Breadcrumb>
          <BreadcrumbItem active>Home</BreadcrumbItem>
        </Breadcrumb>
      )
    }
  }
}

export default BreadCrumbs
