import _ from 'lodash';
import {FETCH_ROLES, FETCH_ALL_ROLES, CREATE_ROLE, FETCH_ROLE, EDIT_ROLE, DELETE_ROLE} from '../actions/types'

export default (state = [], action) => {
    switch (action.type) {
      case FETCH_ROLES:
        return action.payload;
      case FETCH_ALL_ROLES: 
        return action.payload;  
      case FETCH_ROLE:
        return { ...state, [action.payload.id]: action.payload };
      case CREATE_ROLE:
          return { ...state, ['new_product']: action.payload };
      case EDIT_ROLE: 
      return { ...state, [action.payload.id]: action.payload };    
      case DELETE_ROLE:
        return _.omit(state, action.payload );
      default:
        return state;
    }
  };