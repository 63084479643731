import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

class ModalDisplay extends Component {
  onDismiss() {}

  render() {
    return ReactDOM.createPortal(
      <div className="modal" onClick={this.onDismiss}>
        <div onClick={this.onDismiss}>
          <Modal
            isOpen={true}
            className="modal-danger"
            onClick={this.onDismiss}
            contentClassName="custom-modal-style text-center"
            centered
          >
            <ModalHeader>{this.props.title}</ModalHeader>
            <ModalBody>{this.props.content}</ModalBody>
            <ModalFooter>{this.props.actions}</ModalFooter>
          </Modal>
        </div>
      </div>,
      document.querySelector("#modal")
    );
  }
}

export default ModalDisplay;
