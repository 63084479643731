import { MFAdminInitializerActionType } from './MFAdminAppInitializer.types';

/**
 * @typedef {import('./MFAdminAppInitializer.types').MFAdminLayout} MFAdminLayout
 * @typedef {import('./MFAdminAppInitializer.types').MFAdminInitializerAction} MFAdminInitializerAction
 */

/**
 * Reducer function to handle state changes for the admin initializer.
 *
 * @param {MFAdminLayout} state - The current state of the layout.
 * @param {MFAdminInitializerAction} action - The action to be handled.
 * @returns {MFAdminLayout} The new state after applying the action.
 */
export default function mfAdminInitializerReducer(state, { type, payload }) {
  switch (type) {
    case MFAdminInitializerActionType.SET_RTL:
      return {
        ...state,
        isRTL: payload
      };

    case MFAdminInitializerActionType.SET_SKIN:
      return {
        ...state,
        skin: payload
      };

    case MFAdminInitializerActionType.SET_ROUTER_TRANSITION:
      return {
        ...state,
        routerTransition: payload
      };

    case MFAdminInitializerActionType.SET_CONTENT_WIDTH:
      return {
        ...state,
        contentWidth: payload
      };

    case MFAdminInitializerActionType.SET_MENU_HIDDEN:
      return {
        ...state,
        menu: {
          ...state.menu,
          isHidden: payload
        }
      };

    case MFAdminInitializerActionType.SET_MENU_COLLAPSED:
      return {
        ...state,
        menu: {
          ...state.menu,
          isCollapsed: payload
        }
      };

    case MFAdminInitializerActionType.SET_NAVBAR_TYPE:
      return {
        ...state,
        navbar: {
          ...state.navbar,
          type: payload
        }
      };

    case MFAdminInitializerActionType.SET_NAVBAR_BACKGROUND_COLOR:
      return {
        ...state,
        navbar: {
          ...state.navbar,
          backgroundColor: payload
        }
      };

    case MFAdminInitializerActionType.SET_FOOTER_TYPE:
      return {
        ...state,
        footer: {
          ...state.footer,
          type: payload
        }
      };

    case MFAdminInitializerActionType.SET_CUSTOMIZER:
      return {
        ...state,
        customizer: payload
      };

    case MFAdminInitializerActionType.SET_SCROLL_TOP:
      return {
        ...state,
        scrollTop: payload
      };

    default:
      return state;
  }
}
