import { FETCH_SHOW_TAG_SET } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_SHOW_TAG_SET:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
};
