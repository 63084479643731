import { MFAdminInitializerActionType } from './MFAdminAppInitializer.types'
import { localStorageUtility } from '../../utility/Utils'

/** @typedef {import('./MFAdminAppInitializer.types').MFAdminInitializerAction<'SET_RTL', boolean>} SetRTLAction */
/** @typedef {import('./MFAdminAppInitializer.types').MFAdminInitializerAction<'SET_SKIN', import('./MFAdminAppInitializer.types').MFAdminLayoutSkinType>} SetSkinAction */
/** @typedef {import('./MFAdminAppInitializer.types').MFAdminInitializerAction<'SET_ROUTER_TRANSITION', import('./MFAdminAppInitializer.types').MFAdminLayoutRouterTransition>} SetRouterTransitionAction */
/** @typedef {import('./MFAdminAppInitializer.types').MFAdminInitializerAction<'SET_CONTENT_WIDTH', import('./MFAdminAppInitializer.types').MFAdminLayoutContentWidthType>} SetContentWidthAction */
/** @typedef {import('./MFAdminAppInitializer.types').MFAdminInitializerAction<'SET_MENU_HIDDEN', boolean>} SetMenuHiddenAction */
/** @typedef {import('./MFAdminAppInitializer.types').MFAdminInitializerAction<'SET_MENU_COLLAPSED', boolean} SetMenuCollapsedAction */
/** @typedef {import('./MFAdminAppInitializer.types').MFAdminInitializerAction<'SET_NAVBAR_TYPE', import('./MFAdminAppInitializer.types').MFAdminLayoutNavbarTypeType>} SetNavbarTypeAction */
/** @typedef {import('./MFAdminAppInitializer.types').MFAdminInitializerAction<'SET_NAVBAR_BACKGROUND_COLOR', import('./MFAdminAppInitializer.types').MFAdminLayoutNavbarBackgroundColorType>} SetNavbarBackgroundColorAction */
/** @typedef {import('./MFAdminAppInitializer.types').MFAdminInitializerAction<'SET_FOOTER_TYPE', import('./MFAdminAppInitializer.types').MFAdminLayoutFooterTypeType>} SetFooterTypeAction */
/** @typedef {import('./MFAdminAppInitializer.types').MFAdminInitializerAction<'SET_CUSTOMIZER', boolean} SetCustomizerAction */
/** @typedef {import('./MFAdminAppInitializer.types').MFAdminInitializerAction<'SET_SCROLL_TOP', boolean} SetScrollTopAction */

/**
 * Dispatches an action to set the RTL (right-to-left) layout setting.
 *
 * @param {import("react").Dispatch<SetRTLAction>} dispatch - The dispatch function to send the action.
 * @returns {function(boolean): void} - A function that takes a boolean payload and dispatches the action.
 */
const setRTL = dispatch => payload => {
  dispatch({ type: MFAdminInitializerActionType.SET_RTL, payload })
}

/**
 * Dispatches an action to set the skin (dark or light) layout setting.
 *
 * @param {import("react").Dispatch<SetSkinAction>} dispatch - The dispatch function to send the action.
 * @returns {function(import('./MFAdminAppInitializer.types').MFAdminLayoutSkinType): void} - A function that takes a value for the skin as the payload and dispatches the action.
 */
const setSkin = dispatch => payload => {
  dispatch({ type: MFAdminInitializerActionType.SET_SKIN, payload })
  localStorageUtility('set')('skin', payload)
}

/**
 * Dispatches an action to set the router transition (fadeIn, fadeInLeft, zoomIn, or none) layout setting.
 *
 * @param {import("react").Dispatch<SetRouterTransitionAction>} dispatch - The dispatch function to send the action.
 * @returns {function(import('./MFAdminAppInitializer.types').MFAdminLayoutRouterTransitionType): void} - A function that takes a value for the router transition as the payload and dispatches the action.
 */
const setRouterTransition = dispatch => payload => {
  dispatch({ type: MFAdminInitializerActionType.SET_ROUTER_TRANSITION, payload })
}

/**
 * Dispatches an action to set the content width (full, or boxed) layout setting.
 *
 * @param {import("react").Dispatch<SetContentWidthAction>} dispatch - The dispatch function to send the action.
 * @returns {function(import('./MFAdminAppInitializer.types').MFAdminLayoutContentWidthType): void} - A function that takes a value for the content width as the payload and dispatches the action.
 */
const setContentWidth = dispatch => payload => {
  dispatch({ type: MFAdminInitializerActionType.SET_CONTENT_WIDTH, payload })
}

/**
 * Dispatches an action to set the visibility of the menu in layout setting.
 *
 * @param {import("react").Dispatch<SetMenuHiddenAction>} dispatch - The dispatch function to send the action.
 * @returns {function(boolean): void} - A function that takes a boolean as the payload and dispatches the action.
 */
const setMenuHidden = dispatch => payload => {
  dispatch({ type: MFAdminInitializerActionType.SET_MENU_HIDDEN, payload })
}

/**
 * Dispatches an action to set the expansion of the menu in layout setting.
 *
 * @param {import("react").Dispatch<SetMenuCollapsedAction>} dispatch - The dispatch function to send the action.
 * @returns {function(boolean): void} - A function that takes a boolean as the payload and dispatches the action.
 */
const setMenuCollapsed = dispatch => payload => {
  dispatch({ type: MFAdminInitializerActionType.SET_MENU_COLLAPSED, payload })
}

/**
 * Dispatches an action to set the navbar type (static, sticky, floating or hidden) in layout setting.
 *
 * @param {import("react").Dispatch<SetNavbarTypeAction>} dispatch - The dispatch function to send the action.
 * @returns {function(import('./MFAdminAppInitializer.types').MFAdminLayoutNavbarTypeType): void} - A function that takes a value for the navbar type as the payload and dispatches the action.
 */
const setNavbarType = dispatch => payload => {
  dispatch({ type: MFAdminInitializerActionType.SET_NAVBAR_TYPE, payload })
}

/**
 * Dispatches an action to set the navbar background color (white, primary, secondary, success, danger, info, warning or dark) in layout setting.
 *
 * @param {import("react").Dispatch<SetNavbarBackgroundColorAction>} dispatch - The dispatch function to send the action.
 * @returns {function(import('./MFAdminAppInitializer.types').MFAdminLayoutNavbarBackgroundColorType): void} - A function that takes a value for the navbar background color as the payload and dispatches the action.
 */
const setNavbarBackgroundColor = dispatch => payload => {
  dispatch({ type: MFAdminInitializerActionType.SET_NAVBAR_BACKGROUND_COLOR, payload })
}

/**
 * Dispatches an action to set the footer type (static, sticky or hidden) in layout setting.
 *
 * @param {import("react").Dispatch<SetFooterTypeAction>} dispatch - The dispatch function to send the action.
 * @returns {function(import('./MFAdminAppInitializer.types').MFAdminLayoutFooterTypeType): void} - A function that takes a value for the footer type as the payload and dispatches the action.
 */
const setFooterType = dispatch => payload => {
  dispatch({ type: MFAdminInitializerActionType.SET_FOOTER_TYPE, payload })
}

/**
 * Dispatches an action to set the customizer in layout setting.
 *
 * @param {import("react").Dispatch<SetCustomizerAction>} dispatch - The dispatch function to send the action.
 * @returns {function(boolean): void} - A function that takes a boolean as the payload and dispatches the action.
 */
const setCustomizer = dispatch => payload => {
  dispatch({ type: MFAdminInitializerActionType.SET_CUSTOMIZER, payload })
}

/**
 * Dispatches an action to set the scroll top in layout setting.
 *
 * @param {import("react").Dispatch<SetScrollTopAction>} dispatch - The dispatch function to send the action.
 * @returns {function(boolean): void} - A function that takes a boolean as the payload and dispatches the action.
 */
const setScrollTop = dispatch => payload => {
  dispatch({ type: MFAdminInitializerActionType.SET_SCROLL_TOP, payload })
}

export const layoutActions = {
  setRTL,
  setSkin,
  setRouterTransition,
  setContentWidth,
  setMenuHidden,
  setMenuCollapsed,
  setNavbarType,
  setNavbarBackgroundColor,
  setFooterType,
  setCustomizer,
  setScrollTop
}
