import _ from 'lodash';
import { FETCH_CLIENT_ADMINS_BY_USER } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CLIENT_ADMINS_BY_USER:
      return action.payload;
    default:
      return state;
  }
};