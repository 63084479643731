import _ from 'lodash';
import { FETCH_PORTAL_USERS} from '../actions/types'


export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PORTAL_USERS:
        return action.payload ;
    default:
      return state;
  }
};
