//  to delete the devex grid local storage on version change
import packageJson from '/package.json'
export default function HandlePackageJsonVersion() {
  const packageVersion = packageJson.version
  const localStoreVersion = localStorage.getItem('pjv') // pjv is set on the DevexComponent

  if (packageVersion !== localStoreVersion || !packageVersion) {
    for (let key in localStorage) {
      if (key.startsWith('devex') || key.startsWith('mfGrid')) {
        localStorage.removeItem(key)
      }
    }
    localStorage.setItem('pjv', packageVersion)
  }
  return packageVersion
}
