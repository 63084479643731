import _ from 'lodash'
import { CREATE_CHECKOUT_FORM, FETCH_CHECKOUT_FORMS, FETCH_CHECKOUT_FORM, EDIT_CHECKOUT_FORM } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CHECKOUT_FORMS: 
      return action.payload
    case CREATE_CHECKOUT_FORM:
      return [ action.payload, ...state ]
    case EDIT_CHECKOUT_FORM:
    case FETCH_CHECKOUT_FORM:
      return state.map(form => {
        if (form.id === action.payload.id) {
          return action.payload;
        }
        return form
      })
    default:
      return state
  }
}