import { connect } from "react-redux"
import { useMFAdminAppInitializer } from "../../contexts/MFAdminAppInitializer"

const AgencyBanner = ({ currentAgency }) => {
  const { state: { env } } = useMFAdminAppInitializer()

  if (env && env !== 'production') {
    return (
      <div id="current-agency">
        <p>{`${currentAgency?.name}`}</p>
      </div>
    )
  } else {
    return null
  }
}

const mapStateToProps = state => {
  return {
    currentAgency: state.agencies.currentAgency
  }
}

export default connect(mapStateToProps)(AgencyBanner)
