import _ from 'lodash';
import {
          FETCH_ADMINS, 
          DELETE_ADMIN, 
          CREATE_ADMIN, 
          EDIT_ADMIN, 
          FETCH_ADMIN, 
          ADMIN_HAS_ROLES, 
          CLEAR_ADMINS,
          UPDATE_PASSWORD_REQUEST,
          UPDATE_PASSWORD_SUCCESS,
          FORGOT_ADMIN_PASSWORD_REQUEST,
          FORGOT_ADMIN_PASSWORD_SUCCESS,
          RESET_ADMIN_PASSWORD_REQUEST,
          RESET_ADMIN_PASSWORD_SUCCESS, 
          VALIDATE_ADMIN_RESET_TOKEN_REQUEST, 
          VALIDATE_ADMIN_RESET_TOKEN_SUCCESS, 
          VERIFY_ADMIN_PASSWORD_TOKEN_SUCCESS,
          VERIFY_ADMIN_PASSWORD_TOKEN_FAILURE,
        } from '../actions/types'

const initialState = {
  admins: [],
  current_admin: {},
  updatingPassword: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOGOUT':
      return {
        ...state,
        current_admin: {}
      }
    case 'SET_CURRENT_ADMIN':
      return {
        ...state,
        current_admin: action.payload
      }
    case FETCH_ADMIN:
      return {
        ...state,
        [action.payload && action.payload.id]: action.payload
      }
    case FETCH_ADMINS:
       return {
        ...state,
        admins: action.payload,
       }
    case CREATE_ADMIN:
        return {
          ...state,
          [action.payload.id]: action.payload
        }
    case EDIT_ADMIN:
      return { ...state, [action.payload.id]: action.payload };
      // return action.payload
    case DELETE_ADMIN:
      return _.omit(state, action.payload );
    case CLEAR_ADMINS:
      return { ...state, admins: []}
    case ADMIN_HAS_ROLES: 
      return { ...state, ['has_roles']: action.payload };
    case UPDATE_PASSWORD_REQUEST:
      return { ...state, updatingPassword: true };
    case UPDATE_PASSWORD_SUCCESS:
      return { ...state, updatingPassword: false };
    case FORGOT_ADMIN_PASSWORD_REQUEST:
      return {
        ...state,
        forgotAdminPassword: true,
        forgotAdminPasswordSuccess: false,
      };
    case FORGOT_ADMIN_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotAdminPassword: false,
        forgotAdminPasswordSuccess: true,
      };
    case RESET_ADMIN_PASSWORD_REQUEST:
      return { 
        ...state, 
        resettingPassword: true, 
        resetPasswordError: null 
      };
    case RESET_ADMIN_PASSWORD_SUCCESS:
      return { 
        ...state, 
        resettingPassword: false, 
        resetPasswordSuccess: true 
      };
    case VALIDATE_ADMIN_RESET_TOKEN_REQUEST:
      return { 
        ...state, 
        validatingToken: true, 
        tokenValidationError: null 
      };
    case VALIDATE_ADMIN_RESET_TOKEN_SUCCESS:
      return { ...state, 
        validatingToken: false, 
        tokenValid: true 
      };
    case VERIFY_ADMIN_PASSWORD_TOKEN_SUCCESS:
      return { ...state, 
        verifiedToken: action.payload,
        error: null
      };
    case VERIFY_ADMIN_PASSWORD_TOKEN_FAILURE:
      return { ...state, 
        verifiedToken: null,
        error: action.payload
      };
    default:
      return state;
  }
};