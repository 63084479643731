import _ from 'lodash'
import * as Icon from 'react-feather'

// returns the starting navigation object, before considering permissions
const navigation = (skin, admin) => {
  if (admin.is_client_admin) {
    return clientAdminNav
  }

  return {
    items: [
      {
        name: 'Dashboard',
        title: 'Dashboard',
        id: 'dashboard',
        navLink: '/dashboard',
        icon: <i className={`fa-duotone fa-house-${skin === 'dark' ? 'night' : 'day'} menu-duotone-icon-8`} />,
        badge: {
          variant: 'info',
          text: ''
        }
      },
      {
        name: 'Admins',
        id: 'admins',
        title: 'Admin',
        navLink: '/admins',
        entity: 'ADMIN',
        icon: <i className="fa-duotone fa-gears menu-duotone-icon-8" />,
        permissions: ['ADMIN.read'],
        children: [
          {
            name: 'Admins',
            id: 'adminsGrid',
            title: 'Admins',
            navLink: '/admins',
            icon: <Icon.ChevronRight size='1.01rem' />,
            permissions: ['ADMIN.read']
          },
          {
            name: 'Admin Roles',
            title: 'Admin Roles',
            id: 'adminRoles',
            navLink: '/roles',
            icon: <Icon.ChevronRight size='1.01rem' />,
            permissions: ['PERMISSIONS.read']
          }
        ]
      },
      {
        name: 'Clients',
        title: 'Clients',
        id: 'clients_v2',
        entity: 'CLIENT',
        navLink: '/clients',
        icon: <i className="fa-duotone fa-building-magnifying-glass menu-duotone-icon-full" />,
        permissions: ['CLIENT.read', 'PORTAL.read']
      },
      {
        name: 'Portals',
        title: 'Portals',
        id: 'portals',
        entity: 'PORTAL',
        navLink: '/portals',
        icon: <i className="fa-duotone fa-person-to-portal menu-duotone-icon-6" />,
        permissions: [
          'PORTAL.read',
          'PORTAL_CREDIT.read',
          'LOCATION.read',
          'CMS.read',
          'COOP_FUNDING.read',
          'COOP_CREDIT.read',
          'CUST_GROUPS.read'
        ]
      },

      {
        name: 'Customers',
        title: 'Customers',
        id: 'customers',
        navLink: '/customers',
        icon: <i className="fa-duotone fa-users menu-duotone-icon-8" />,
        permissions: ['USER.read', 'GIFT_CARD.read', 'COOP_CREDIT.read', 'PORTAL_CREDIT.read']
      },
      {
        name: 'Products',
        title: 'Products',
        id: 'products',
        navLink: '/products',
        icon: <i className="fa-duotone fa-cake-slice menu-duotone-icon-7" />,
        permissions: ['ARTWORK.read', 'PRODUCT.read', 'PRODUCT_WIZARD.create', 'BUNDLE_WIZARD.create'],
        children: [
          {
            name: 'Products',
            title: 'Products',
            id: 'productsGrid',
            navLink: '/products',
            entity: 'PRODUCT',
            icon: <Icon.ChevronRight size='1.01rem' />,
            permissions: ['PRODUCT.read', 'PRODUCT_WIZARD.create', 'BUNDLE_WIZARD.create']
          },
          {
            name: 'Artworks',
            title: 'Artworks',
            id: 'artworksList',
            navLink: '/artworks',
            entity: 'ARTWORK',
            icon: <Icon.ChevronRight size="1.01rem" />,
            permissions: ['ARTWORK.read', 'PRODUCT.read'],
          },
          {
            name: 'Configuration',
            title: 'Configuration',
            id: 'configuration',
            navLink: '/products/configurable_variants',
            icon: <Icon.ChevronRight size='1.01rem' />,
            permissions: ['PRODUCT.read']
          },
          {
            name: 'Product Templates',
            title: 'Product Templates',
            id: 'productTemplates',
            navLink: '/templates',
            icon: <Icon.ChevronRight size='1.01rem' />,
            permissions: ['PRODUCT.read']
          },
          {
            name: 'ShipperHQ',
            id: 'ShipperHQ',
            title: 'ShipperHQ',
            navLink: '/',
            icon: <Icon.ChevronRight size='1.01rem' />,
            permissions: ['ORDER.read'],
            children: [
              {
                name: 'Boxes',
                id: 'ShipperHqList',
                title: 'Boxes',
                navLink: '/products/shq_boxes',
                icon: <Icon.ChevronRight size='1.01rem' />,
                permissions: ['ORDER.read']
              },

              {
                name: 'Origins',
                id: 'ShipperHqOriginList',
                title: 'Origins',
                navLink: '/products/shq_origins',
                icon: <Icon.ChevronRight size='1.01rem' />,
                permissions: ['ORDER.read']
              },
              {
                name: 'Groups',
                id: 'ShipperHqGroupsList',
                title: 'Groups',
                navLink: '/products/shq_shipping_groups',
                icon: <Icon.ChevronRight size='1.01rem' />,
                permissions: ['ORDER.read']
              },
              {
                name: 'Rules',
                id: 'ShipperHqRulesist',
                title: 'Rules',
                navLink: '/products/shq_packing_rules',
                icon: <Icon.ChevronRight size='1.01rem' />,
                permissions: ['ORDER.read']
              }
            ]
          }
        ]
      },
      {
        name: 'Orders',
        id: 'orders',
        title: 'Orders',
        navLink: '/orders',
        icon: <i className="fa-duotone fa-cart-shopping menu-duotone-icon-6" />,
        permissions: ['ORDER.read'],
        children: [
          {
            name: 'Orders',
            id: 'ordersGrid',
            title: 'Orders',
            navLink: '/orders',
            icon: <Icon.ChevronRight size='1.01rem' />,
            permissions: ['ORDER.read']
          },
          // {
          //   name: 'Fulfillment',
          //   id: 'fulfillment',
          //   title: 'Fulfillment',
          //   navLink: '/fulfilment',
          //   icon: <Icon.ChevronRight size='1.01rem' />,
          //   permissions: ['ORDER.read']
          // },
          {
            name: 'Fulfillment',
            id: 'fulfillment',
            title: 'Fulfillment',
            navLink: '/fulfillment',
            icon: <Icon.ChevronRight />,
            permissions: ["ORDER.read"],
          },
          {
            name: 'Dropshipping',
            id: 'dropship',
            title: 'Dropshipping',
            navLink: '/dropship',
            icon: <Icon.ChevronRight size='1.01rem' />,
            permissions: ['ORDER.read']
          },
          {
            name: 'Order Review',
            title: 'Order Review',
            id: 'orderApproval',
            navLink: '/orders/review',
            icon: <Icon.ChevronRight size='1.01rem' />,
            permissions: ['ORDER_APPROVAL.read', 'ORDER.read']
            // permissions: [ 'ORDER.read'],
          },
          {
            name: 'Log',
            id: 'OrderLogsList',
            title: 'Orders Logs',
            navLink: '/orders/log',
            icon: <Icon.ChevronRight size='1.01rem' />,
            permissions: ['ORDER.read']
          },
          {
            name: 'Order Async Errors',
            id: 'OrderErrorsGrid',
            title: 'Order Errors',
            navLink: '/orders/errors',
            icon: <Icon.ChevronRight size='1.01rem' />,
            permissions: ["SUPER_ADMIN.read"]
          }
        ]
      },
      {
        name: 'Stock Mgmt',
        title: 'Stock Mgmt',
        navLink: '/stock',
        id: 'stock',
        icon: <i className="fa-duotone fa-warehouse-full menu-duotone-icon" />,
        permissions: ['WAREHOUSE.read', 'PURCHASE_ORDER.read', 'VENDORS.read'],
        children: [
          {
            name: 'Warehouses',
            title: 'Warehouses',
            id: 'warehouses',
            entity: 'WAREHOUSE',
            navLink: '/warehouses',
            icon: <Icon.ChevronRight size='1.01rem' />,
            permissions: ['WAREHOUSE.read']
          },
          {
            name: 'Purchase Orders',
            title: 'Purchase Orders',
            entity: 'PURCHASE_ORDER',
            id: 'purchase_order',
            navLink: '/purchase_orders',
            icon: <Icon.ChevronRight size='1.01rem' />,
            permissions: ['PURCHASE_ORDER.read']
          },
          {
            name: 'Vendors',
            title: 'Vendors',
            id: 'vendors',
            entity: 'VENDORS',
            navLink: '/vendors',
            icon: <Icon.ChevronRight size='1.01rem' />,
            permissions: ['VENDORS.read']
          }
        ]
      },
      // {
      //   name: 'Payment',
      //   title: 'Payment',
      //   id: 'payment',
      //   navLink: '/card_payment',
      //   icon: <Icon.CreditCard />,
      //   permissions: ['PAYMENT.read', 'COUPON.read'],
      //   children: [
      //     {
      //       name: 'Transactions List',
      //       id: 'transactionsList',
      //       title: 'Transactions List',
      //       navLink: '/card_transactions_list',
      //       icon: <Icon.ChevronRight size='1.01rem' />,
      //       permissions: ['PAYMENT.read'],
      //     },
      //     {
      //       name: 'Promotions',
      //       id: 'promotions',
      //       title: 'Promotions',
      //       navLink: '/promotions',
      //       icon: <Icon.ChevronRight size='1.01rem' />,
      //       permissions: ['COUPON.read'],
      //     },
      //   ],
      // },
      // {
      //   name: 'Agencies',
      //   title: 'Agencies',
      //   navLink: '/agencies',
      //   id: 'agencies',
      //   icon: <Icon.Database />,
      //   permissions: ['AGENCIES.read'],
      // children: [
      //   {
      //     name: 'Warehouses',
      //     title: 'Warehouses',
      //     id: 'warehouses',
      //     entity: 'WAREHOUSE',
      //     navLink: '/warehouses',
      //     icon: <Icon.ChevronRight size='1.01rem' />,
      //     permissions: ['WAREHOUSE.read'],
      //   },
      //   {
      //     name: 'Purchase Orders',
      //     title: 'Purchase Orders',
      //     entity: 'PURCHASE_ORDER',
      //     id: 'purchase_order',
      //     navLink: '/purchase_orders',
      //     icon: <Icon.ChevronRight size='1.01rem' />,
      //     permissions: ['PURCHASE_ORDER.read'],
      //   },
      //   {
      //     name: 'Vendors',
      //     title: 'Vendors',
      //     id: 'vendors',
      //     entity: 'VENDORS',
      //     navLink: '/vendors',
      //     icon: <Icon.ChevronRight size='1.01rem' />,
      //     permissions: ['VENDORS.read'],
      //   },
      // ],
      // },
      // {
      //   name: 'Payment',
      //   title: 'Payment',
      //   id: 'payment',
      //   navLink: '/card_payment',
      //   icon: <i className="fa-duotone fa-credit-card menu-duotone-icon" />,
      //   permissions: ['PAYMENT.read', 'COUPON.read'],
      //   // {
      //   //   name: 'Transactions',
      //   //   id: 'transactionsList',
      //   //   title: 'Transactions',
      //   //   navLink: '/card_transactions_list',
      //   //   icon: <Icon.ChevronRight size='1.01rem' />,
      //   //   permissions: ['PAYMENT.read'],
      //   // },
      // },
      {
        name: 'Promotions',
        id: 'promotions',
        title: 'Promotions',
        navLink: '/promotions',
        icon: <i className="fa-duotone fa-badge-percent menu-duotone-icon-5" />,
        permissions: ['COUPON.read']
      },
      {
        name: 'Reporting',
        id: 'reporting',
        title: 'Reporting',
        navLink: '/reporting',
        icon: <i className="fa-duotone fa-chart-pie menu-duotone-icon-8" />,
        permissions: ['REPORTING.read']
      },
      {
        name: 'Feedback',
        id: 'feedback',
        title: 'Feedback',
        navLink: `https://feedback.marketfuel.com?jwt=${admin.featurebase_authentication_token}`,
        newTab: true,
        externalLink: true,
        icon: <i className="fa-duotone fa-lightbulb-on menu-duotone-icon-8" />,
        style: { marginTop: 'auto' }
      }
    ]
  }
}

const clientAdminNav = {
  items: [
    {
      name: 'Order Review',
      title: 'Order Review',
      id: 'orderRevierAadmin',
      navLink: '/orders/review',
      icon: <Icon.CheckCircle />,
      permissions: ['ORDER_APPROVAL.update', 'ORDER.read']
      // permissions: [ 'ORDER.read'],
    }
  ]
}

// filters out nav tabs that should be hidden based on admin role
const getNavigationForAdmin = (permissions, admin, skin = 'light') => {
  // TODO: Always filter out Agencies section unless admin has AGENCIES.read permission
  // if (!admin.organization_admins || admin.organization_admins.length === 0) {
  //   console.error('Admin is not associated with any organization')
  //   return []
  // }

  const isSuperAdmin = permissions === false
  const nav = navigation(skin, admin)
  if (isSuperAdmin) {
    return nav // Super admin - return all navigation items
  }

  // Client admin with specific order-related permissions
  // const hasOrderRelatedPermissions = permissions => permissions.every(permission => permission.includes('ORDER_APPROVAL') || permission.includes('ORDER'))

  // if (admin.is_client_admin && permissions.length > 0 && hasOrderRelatedPermissions(permissions)) {
  //   return admin.client_account_managers.some(cam => cam.is_order_approver) ? clientAdminNav : {}
  // }

  // Filter navigation items based on user permissions
  const filteredNavigationItems = nav.items.filter(item => {
    if (!item.permissions) {
      // Include item if it does not require permissions
      return true
    }

    // Filter out childrens based on permissions
    const children = (item.children || []).filter(child => {
      if (!child.permissions) {
        return true
      }

      return child.permissions.some(requiredPermission => permissions.includes(requiredPermission))
    })

    if (children.length !== 0) {
      item.children = children
    }

    // Check if user has at least one of the required permissions for this item
    return item.permissions.some(requiredPermission => permissions.includes(requiredPermission))
  })

  // Clone the navigation object to avoid mutating the original
  const navigationClone = _.cloneDeep(nav)
  navigationClone.items = filteredNavigationItems

  return navigationClone
}

export default getNavigationForAdmin
