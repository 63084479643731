import React, { Component } from 'react'
import { connect } from 'react-redux';
import ModalDisplay from '../ModalDisplay'
import history from '../../history'
import { fetchPromotionValidation, deletePromotionValidation } from '../../actions'

import {
  Button,
} from 'reactstrap';

class PromotionValidationDelete extends Component {
  componentDidMount() {
    this.props.fetchPromotionValidation(this.props.match.params.id)
  }

  renderActions() {
    return (
      <React.Fragment>
        <Button size="sm" color="danger" className="btn-left-space" onClick={() => this.props.deletePromotionValidation(this.props.match.params.id)}> Delete </Button>
        <Button size="sm" outline color="danger" className="btn-left-space" onClick={history.back}> Cancel </Button>
      </React.Fragment>
    );
  }

  renderContent() {
    if (!this.props.promotion_validation) {
      return 'Are you sure you want to delete this validation?';
    }

    return (
      <div>
        Are you sure you want to delete this validation?
      </div>
    )
  }

  render() {
    return (
      <ModalDisplay
        style={{ maxWidth: "35%", width: "35%", margin: "10px auto" }}
        title="Are you sure you want to delete this validation?"
        content={this.renderContent()}
        actions={this.renderActions()}
        onDismiss={() => history.back()}
      />
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return { promotion_validation: state.promotion_validations[ownProps.match.params.id]}
}

export default connect(mapStateToProps, { fetchPromotionValidation, deletePromotionValidation })(PromotionValidationDelete);

// export for testing
export { PromotionValidationDelete };