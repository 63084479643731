
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {

    const { children, ...attributes } = this.props;

    const year = new Date().getFullYear()

    return (
      <React.Fragment>
        <div className="mfcopyright" style={{textAlign: 'right'}}>
        <span>
          {/* <a href="https://agency.branditportal.com/" target='_blank'>MarketFuel</a>  */}
          &copy; MarketFuel {year}
        </span>
        </div>
        {/* <span className="ml-auto">Powered by <a href="https://www.formulatedigital.co.uk/" target='_blank'>Formulate Digital</a></span> */}
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
