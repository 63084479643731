import _ from 'lodash';
import { FETCH_WAREHOUSES, 
  CREATE_WAREHOUSE, 
  FETCH_WAREHOUSE,
  EDIT_WAREHOUSE, 
  FETCH_WAREHOUSES_FILTERED_BY_PRODUCTS, 
  FETCH_WAREHOUSE_STOCK,
  EDIT_WAREHOUSE_STOCKS
 } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_WAREHOUSES:
        return _.mapKeys(action.payload, 'id');
    case CREATE_WAREHOUSE:
      if (action.payload && action.payload.id) {
        return { ...state, [action.payload.id]: action.payload };
      } else {
        return state
      }
    case FETCH_WAREHOUSE:
      if (action.payload && action.payload.id) {
        return { ...state, [action.payload.id]: action.payload };
      } else {
        return state
      }
    case EDIT_WAREHOUSE:
      if (action.payload && action.payload.id) {
        return { ...state, [action.payload.id]: action.payload };
      } else {
        return state
      }
    case FETCH_WAREHOUSES_FILTERED_BY_PRODUCTS:
      return { ...state, filtered_by_products: action.payload };
    case FETCH_WAREHOUSE_STOCK:
      return { ...state, selectedStock: action.payload };
    case EDIT_WAREHOUSE_STOCKS:
      return { ...state, selectedStock: action.payload };
    case 'CLEAR_WAREHOUSES':
      return [];
    default:
      return state;
  }
};
