import { CREATE_PURCHASE_ORDER, FETCH_PURCHASE_ORDER, FETCH_PURCHASE_ORDERS } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case CREATE_PURCHASE_ORDER:
      return {...state, ["new_purchase_order"]: action.payload};
    case FETCH_PURCHASE_ORDER:
      return  {...state, [action.payload.id]: action.payload};
    // case FETCH_PURCHASE_ORDERS:
    //   return action.payload;
    default:
      return state;
  }
};
