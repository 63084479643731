import _ from 'lodash'
import { FETCH_TAGS } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_TAGS:
      return action.payload
    case 'CREATE_SUB_ORDER_TAG':
      return [...state, action.payload]
    default:
      return state
  }
}
