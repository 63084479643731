import React, { useEffect } from 'react'
import { fetchCurrentAdmin, setLoading } from './actions'
import { connect } from 'react-redux'
import MFRouter from './router/MFRouter'
import MfSweetAlert from './components/general/MfSweetAlert'
import useEE from './components/hooks/easterEgg'
import { currentEnvironment } from './utility/Utils'
import HandlePackageJsonVersion from './components/general/HandlePackageJsonVersion'
import { fetchAdminPermissions } from './actions/index'
import { fetchCurrentAgency, redirectToLogin } from './actions/agencies'
import { handleLogout } from './redux/actions/auth'
import 'react-toastify/dist/ReactToastify.css'
import { saveAdminDataToSession, setSessionToken } from './actions/sessions'
import useVersionCheck from './hooks/useVersionCheck'

HandlePackageJsonVersion()

const App = ({
  fetchCurrentAdmin,
  fetchCurrentAgency,
  fetchAdminPermissions,
  currentAdmin,
  currentAgency,
  loading,
  handleLogout,
  session,
  setSessionToken,
  setLoading
}) => {

  useEE()
  // useVersionCheck()

  // when the app loads, if token is present in storage, set it to redux session
  useEffect(() => {
    // ignore if we're attempting SSO login
    if (window.location.pathname.includes('sso-log-in') || window.location.pathname.includes('auth0')) {
      return
    }
    const storageToken = sessionStorage.getItem('agencyAuthToken')
    if (storageToken) {
      setSessionToken(storageToken)
    } else {
      setLoading('session', false)
      setLoading('admin', false)
    }
  }, [])

  // when session is set, fetch admin details
  useEffect(() => {
    if (!currentAdmin.id) {
      if (session) {
        setLoading('admin', true)

        fetchCurrentAdmin()
          .then(() => {
            setLoading('admin', false)
          })
          // .catch(err => {
          //   if (!window.pathname?.includes('auth0') && !window.pathname?.includes('sso-log-in')) {
          //     console.error(err)
          //     handleLogout().then(() => {
          //       redirectToLogin()
          //     })
          //   }
          // })
      } else {
        // if there's no session and no loading is happening, redirect to login
        if (!loading.admin && !loading.session) {
          redirectToLogin()
        }
      }
    }
  }, [session, loading.session])

  // fetch agency details after currentAdmin is set
  useEffect(() => {
    if (currentAdmin.id && loading.admin) {
      setLoading('admin', false)
    }
    if (currentAdmin.id && !currentAgency && session) {
      // auth token present on admin, fetching current agency
      fetchCurrentAgency().catch(() => {
        console.error('Failed to fetch agency')
        // if fetching the agency fails here, something went wrong. We need the agency
        handleLogout()
      })
    }
  }, [currentAdmin.id])

  // fetch permissions
  useEffect(() => {
    if (currentAdmin.id) {
      fetchAdminPermissions(currentAdmin.id)
    }
  }, [currentAdmin.id])


  const shouldShowLoading = loading

  return (
    <>
      <MfSweetAlert />
      <MFRouter />
    </>
  )
}

const mapStateToProps = state => {
  return {
    currentAdmin: state.admins.current_admin,
    themeMode: state.layout.themeMode,
    loading: state.loading,
    currentAgency: state.agencies.current_agency,
    session: state.session
  }
}

export default connect(mapStateToProps, {
  fetchCurrentAdmin,
  fetchAdminPermissions,
  fetchCurrentAgency,
  handleLogout,
  setSessionToken,
  setLoading
})(App)
