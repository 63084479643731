import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import BreadCrumbs from '../components/general/BreadCrumbs.js'
import { isUserLoggedIn } from '../utility/Utils.js'

export default function FinalRoute({ route, ...rest }) {
  const [crumbs, setCrumbs] = useState([{ name: 'Testcrumb', link: '/specificlink' }])

  const currentAdmin = useSelector(state => state.admins.current_admin)
  const {push} = useHistory()

  // check if current admin has permission to access the route
  const clientAdminAllowedPaths = useMemo(
    () => ['/', '/dashboard', '/orders/review', '/order_approval', '/update_password'],
    []
  )

  const handleRedirect = useCallback(() => {
    if (currentAdmin.is_client_admin) {
      if (!clientAdminAllowedPaths.includes(route.path)) {
        toastr.error('Attention', "You don't have permission to access this page", {
          className: css({ background: 'red !important' })
        })

        push('/orders/review')
      }
    }
  }, [currentAdmin.is_client_admin, clientAdminAllowedPaths, route.path])

  useEffect(() => {
    handleRedirect()
  }, [handleRedirect])

  const handleBreadcrumb = useCallback(() => {
    const splitBySlash = route.path.split('/')
    const formattedLink = splitBySlash.splice(0, 1).join('/')

    const isRootRoute = splitBySlash.length <= 2

    const breadcrumb = {
      name: route.breadcrumb || route.name,
      link: formattedLink,
      root: isRootRoute
    }

    const crumbsArray = []

    if (breadcrumb.root) crumbsArray.push(breadcrumb)
    else {
      if (crumbsArray.findIndex(i => i.name === breadcrumb.name) < 0) crumbsArray.push(breadcrumb)
      else {
        const crumbIndex = crumbsArray.findIndex(i => i.name === breadcrumb.name)
        crumbsArray.splice(crumbIndex + 1, crumbsArray.length)
      }
    }

    setCrumbs(crumbsArray)
  }, [route])

  useEffect(() => {
    handleBreadcrumb()
  }, [handleBreadcrumb])

  const handleAuth = useCallback(() => {
    if (
      (!isUserLoggedIn() && route.meta === undefined) ||
      (!isUserLoggedIn() &&
        route.meta &&
        !route.meta.authRoute &&
        !route.meta.publicRoute)
    ) push('/login')
  }, [route.meta])

  useEffect(() => {
    handleAuth()
  }, [handleAuth])

  return (
    <>
      <BreadCrumbs crumbs={crumbs} />
      <route.component {...rest} />
    </>
  )
}
