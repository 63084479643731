import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import ForgotPasswordForm from '../../components/sessions/ForgotPasswordForm'
import SessionNew from '../../components/sessions/SessionNew'
import ResetPasswordForm from '../../components/sessions/ResetPasswordForm'
import Auth0Callback from '../../components/sessions/Auth0Callback'
import SSoSignInPage from '../../components/sessions/SSoSignInPage'

const SessionsLayout = ({ currentAdmin, session, loading }) => {
  const loggedIn = session || currentAdmin?.id > 0
  return (
    <div className="app">
      <Switch>
        {loggedIn && !loading.session && !loading.admin ? <Redirect to={'/'} /> : null}
        <Route exact path="/login"  component={SessionNew} />
        <Route exact path="/forgot_password"  component={ForgotPasswordForm} />
        <Route exact path="/reset_password/:token"  component={ResetPasswordForm} />
        <Route exact path="/auth0_callback"  component={Auth0Callback} />
        {/* <Route exact path="/sso-log-in/:sso_id/:sso_token"  component={SSoSignInPage} /> */}
      </Switch>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentAdmin: state.admins.current_admin,
    session: state.session,
    loading: state.loading
  }
}

export default connect(mapStateToProps)(SessionsLayout)
