import _ from 'lodash'

export default (state = '', action) => {
  switch (action.type) {
    case 'CREATE_SESSION':
      const token = action.payload
      sessionStorage.setItem('agencyAuthToken', token)
      return token
    default:
      return state
  }
}
