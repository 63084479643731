import _ from 'lodash';
import { PREVIEW_ARTWORK} from '../actions/types'


export default (state = [], action) => {
  switch (action.type) {
    case PREVIEW_ARTWORK:
      return action.payload
    default:
      return state;
  }
};
